<template>
    <div style="width:100%;height:100%">
        <div style="width: 100%;height:6%;border-bottom:0.5px black solid">
            <span style="font-size:20px;float:left;margin-left:20px;">
                年后
            </span>
        </div>
        <div class="cla" ref="context">
            <div style="width:100%;" v-for="data in datas">
                <div style="width:100%;float:left;margin-top:10px"
                    v-if="data.type==1">
                    <div style="width: 60%;">
                        <div style="width:40px;height:40px;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                            <img src="../imgs/avatar3.jpg" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="float:left;margin-left:10px;font-size:10px">
                            2023-05-23 12:56:12
                        </div>
                        <div style="width:80%;background-color:whitesmoke;float:right;margin-right:10px;margin-top:6px;
                        border-radius: 5px;">
                            <div style="width:100%;float:left;list-style: none;display: block;word-break: break-all;">
                                好久不见，泡泡龙
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width:100%;float:left;margin-top:10px"
                    v-if="data.type==2">
                    <div style="width:400px;margin-left:160px">
                        <div style="font-size:10px;height:10px;margin-left:230px;float:left;margin-bottom:-12px">
                            2023-05-23 12:56:12
                        </div>
                        <div style="width:70%;background-color:chartreuse;float:left;margin-left:75px;margin-top:20px;border-radius: 5px;
                        word-break: break-all;">
                            好久不见，靓仔
                        </div>
                        <div style="width:40px;height:40px;float:right;margin-top:6px;overflow: hidden;">
                            <img src="../imgs/avatar3.jpg" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" style="width:85%">
            </el-input>
            <el-button size="medium" style="position: absolute;margin-top: 10px;margin-left:5px" @click="to">发送</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Test",
    beforeRouteUpdate(to, from, next) {
        //next方法使URL显示正常
        next()
        this.scrollToBottom();
    },
    data() {
        return {
            datas: [
                { 'text': "11111111111111111111",'type':1 },
                { 'text': "22222222222222222222",'type':1 },
                { 'text': "33333333333333333333",'type':1 },
                { 'text': "44444444444444444444",'type':2 },
                { 'text': "55555555555555555555",'type':1 },
                { 'text': "66666666666666666666",'type':1 },
                { 'text': "77777777777777777777",'type':2 },
                { 'text': "11111111111111111111",'type':2 },
                { 'text': "22222222222222222222",'type':2 },
                { 'text': "33333333333333333333",'type':1 },
                { 'text': "44444444444444444444",'type':1 },
                { 'text': "55555555555555555555",'type':1 },
                { 'text': "66666666666666666666",'type':2 },
                { 'text': "77777777777777777777",'type':1 },
            ],

        }
    },
    methods: {
        scrollToBottom() {
            // this.$nextTick 将回调延迟到下次DOM更新循环之后执行。在修改数据之后立即使用它，然后等待DOM更新
            this.$nextTick(() => {
                // dom 元素更新后执行滚动条到底部 否则不生效
                let scrollElem = this.$refs.context;
                console.log('scrollHeight: ', scrollElem.scrollHeight);
                // scrollElem.scrollTop = scrollElem.scrollHeight
                scrollElem.scrollTo({
                    top: scrollElem.scrollHeight,
                    behavior: 'smooth'
                });
            });
        },
        getList(){
            this.$axios.post('user/test').then(resp => {
                this.$router.push({ name: "Chat", query: { id: id, name: name } });
            });
        }
    },
    mounted() {
        this.getList();
        this.scrollToBottom();
    }
}
</script>

<style scoped>
.cla {
    width: 100%;
    height: 84%;
    border-bottom: 0.5px black solid;
    overflow-y: auto;
}

/* 整个滚动条 */
.cla::-webkit-scrollbar {
    width: 8px;
}

/* 滚动条里面滑块 */
.cla::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: skyblue;
}

/*滚动条里面轨道*/
.cla::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background: #eee;
}
</style>

