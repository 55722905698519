// 引入js
import Vue from 'vue'
import VueRouter from 'vue-router'
import Element from "element-ui";

// 路由集成到vue中
Vue.use(VueRouter);

/* 解决导航重复问题 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


//引入路由配置
import routes from '@/router/routes.js';

//实例路由对象
var router=new VueRouter({
    routes,
	mode: 'hash'
})

import {setTitle} from '@/util/index.js'


router.beforeEach((to,from,next)=>{
	let token = localStorage.getItem("token")
	if (to.path == '/login' ||to.path == '/register') {
		next()
	} else if (!token) {
		next({path: '/login'})
	}else if(token=="" || token==undefined || token==null){
		Element.Message.error("请登录！")
		next({path: '/login'})
	}
	localStorage.setItem("router",to.path);
	//setTitle(to.meta.title);
    next();
})

//导出模块
export default router