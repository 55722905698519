import { render, staticRenderFns } from "./ImgPen.vue?vue&type=template&id=295b062c&scoped=true&"
import script from "./ImgPen.vue?vue&type=script&lang=js&"
export * from "./ImgPen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295b062c",
  null
  
)

export default component.exports