<template>
  <div style="width:100%;height:100%">
    <div id="left">
      <div style="width:90%;height:70%;margin-left:5%;overflow: hidden;">
        <img src="@/imgs/dec.webp" width="100%" height="100%" style="object-fit: cover;" />
      </div>
    </div>
    <div id="right">
      <span id="sp">
        <a href="#" @click="toMessage" :style="t1 == true ? { color: '#66b1ff' } : { color: 'black' }">
          登录
        </a>
        <!-- /<a href="#" @click="toFriend" :style="t2 == true ? { color: '#66b1ff' } : { color: 'black' }">
          注册
        </a> -->
      </span>
      <div id="z">
        <router-view />
      </div>
    </div>
    <div style="width:100%;text-align: center;position: absolute;bottom: -60px;right: 0;">
      <a href="https://beian.miit.gov.cn" id="beian" target="_blank" style="text-decoration: none;color:black;font-size:13px;">
      湘ICP备2023021155号
    </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      t1: true,//登录
      t2: false,//注册
    }
  },
  methods: {
    //登录
    toMessage() {
      this.t1 = true;
      this.t2 = false;
      this.$router.push({ name: "Enroll" });
    },
    //注册
    toFriend() {
      this.t1 = false;
      this.t2 = true;
      this.$router.push("/register");
    },
  },
}
</script>

<style scoped>
#left {
  width: 56%;
  height: 100%;
  float: left;
  margin-top: 8%;
}

#right {
  width: 44%;
  height: 100%;
  float: right;
}

#z {
  width: 360px;
  height: 300px;
  margin-top: 33%;
  margin-left: 2%;
}

#sp {
  float: right;
  margin-right: 9%;
  margin-top: 22%;
}

a {
  text-decoration: none;
}
</style>