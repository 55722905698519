import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'amfe-flexible';
import autofit from 'autofit.js'

// import 'default-passive-events'

//引入qs
import qs from 'qs'

//配全局属性配置，在任意组件内可以使用this.$qs获取qs对象
 Vue.prototype.$qs = qs

//引入elementui
Vue.use(ElementUI);

import axios from '@/util/request.js'
Vue.prototype.$axios = axios 

//vue原型链挂载总线,数据存储组件通讯
Vue.prototype.bus = new Vue();

// axios.defaults.withCredentials = true;

import ws from './util/ws.js' // 引入 websocket
Vue.prototype.$ws = ws // 挂载

import url from './util/url.js'
Vue.prototype.$url=url

//全局peer服务！
import peers from './util/peers.js'
Vue.prototype.$peer=peers

window.addEventListener('unhandledrejection', function browserRejectionHandler(event) { // 禁用Promise reject输出控制台
  event && event.preventDefault()
})


import watchLocalStorage from './util/watchLocalStorage' //监听LocalStorage事件
Vue.use(watchLocalStorage)

import router from '@/router/index.js'
import store from '@/store/index.js'
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router:router,
  store:store
}).$mount('#app')
