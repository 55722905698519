<template>
    <div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" size="large">
            <el-form-item prop="userId">
                账号：<el-input v-model="loginForm.userId" placeholder="请输入账号" style="width:80%;" />
            </el-form-item>
            <el-form-item prop="userPass">
                密码：<el-input v-model="loginForm.userPass" type="password" placeholder="请输入密码" style="width:80%;" />
            </el-form-item>
            <el-form-item prop="code">
                <el-input v-model="loginForm.code" placeholder="验证码" clearable style="width:50%;margin-left: 11.5%;"
                    maxlength="4" />
                <a href="javascript:void(0)" style="position: absolute;wihth:200px;height:30px;margin-top:5px"
                    @click="createCode">
                    <img :src="verifitycode" style="width:100%;" alt="图片加载失败" />
                </a>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width:100%;background-color:#66b1ff" @click="submitForm">
                    <span style="font-size: 18px;">登 录</span>
                </el-button>
            </el-form-item>
        </el-form>
        <!--弹出层-->
        <el-dialog :visible.sync="flag" append-to-body :close-on-click-modal="false" width="400px">
            <div style="width: 100%;height:70px;margin-top:-20px">
                <div style="width: 5%;height:100%;float:left"></div>
                <div style="float: left;width:30%;height:100%;overflow: hidden;">
                    <img src="../../imgs/code_2.jpeg" width="100%" height="100%" style="object-fit: contain;" />
                </div>
                <div style="width: 7%;height:100%;float:left"></div>
                <div style="float:left;width: 40%;height:100%;color:#66b1ff;">
                    <h1>憨憨小栈</h1>
                </div>
            </div>
            <p></p>
            <div style="width:100%;height:30px;color:red;font-size:12px">
                <span>检测到当前为非常规IP地址登录,为确保您的账户安全,请使用验证码登录。</span>
            </div>
            <p></p>
            <div style="width:100%;">
                <el-input v-model="loginForm.userId" disabled style="color:black"></el-input>
                <p></p>
                <el-input v-model="code" style="width: 60%;"></el-input>
                <el-button style="width:34%;margin-left: 20px;background-color:rgba(255, 255, 255, 0.247);"
                    @click="sendEmail" v-if="isCode == true">
                    获取验证码
                </el-button>
                <el-button style="width:34%;margin-left: 20px;background-color:rgba(255, 255, 255, 0.247);color:#66b1ff" 
                disabled v-if="isCode == false">
                    {{ t_Seconds }}
                </el-button>
                <p></p>
                <el-button type="primary" style="width:100%;background-color:#66b1ff" @click="loginWithCode">登录</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                //密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
                var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?+-\/]).*$/;
                if (!pPattern.test(value)) {
                    callback(new Error('最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符'));
                } else {
                    callback();
                }
            }
        }
        var validateUsername = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入账号'));
            } else {
                var uPattern = /^.*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!uPattern.test(value)) {
                    callback(new Error('qq邮箱账号'));
                } else {
                    callback();
                }
            }
        }
        return {
            loginForm: {
                userId: '',
                userPass: '',
                code: ''
            },
            verifitycode: "",//图片验证码
            loginRules: {
                userId: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { validator: validateUsername, trigger: 'blur' },
                ],
                userPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'change' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
            },
            flag: false,//ip校验弹窗
            code: '',//验证码
            isCode: true,//显示验证码有效时间
            time: 60,
        }
    },
    methods: {
        submitForm() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    //校验验证码
                    this.$axios.get("code/equalsCode/" + this.loginForm.code).then(resp => {
                        if (resp.code == 600) {
                            this.$message({
                                showClose: true,
                                message: resp.message,
                                type: 'error',
                            });
                            //重新加载验证码
                            this.createCode();
                        } else {
                            this.$axios.post('user/login', this.loginForm).then(resp => {
                                if (resp.data.code === 200) {
                                    this.$message({
                                        showClose: true,
                                        message: "登录成功",
                                        type: 'success',
                                    });
                                    //将token和登录用户信息保存在本地
                                    localStorage.setItem("token", resp.data.data.token)
                                    localStorage.setItem("user", JSON.stringify(resp.data.data.user));
                                    //将token和登录用户信息保存在vuex中
                                    // this.$store.commit('setUser',resp.data.data.user);
                                    // this.$store.commit('SET_TOKEN',resp.data.data.token);
                                    this.$router.push('/');
                                } else if (resp.code === 1001) {
                                    //弹出ip校验弹框
                                    this.flag = true;
                                }
                            });
                        }
                    });
                }
            });
        },
        //获得图片验证码
        createCode() {
            //二进制方式读取图片流  验证码
            this.$axios.get("/code/getCode", {
                responseType: "arraybuffer"
            }).then(response => {
                return (
                    "data:image/png;base64," +
                    btoa(
                        new Uint8Array(response).reduce(
                            (data, byte) => data + String.fromCharCode(byte), ""
                        )
                    )
                );
            })
                .then(data => {
                    this.verifitycode = data;
                });
        },
        //验证码登录
        loginWithCode() {
            var user={
                "userId":this.loginForm.userId,
                "qqCode":this.code
            }
            this.$axios.post('user/loginWithQQCode', user).then(resp => {
                this.$message({
                    showClose: true,
                    message: "登录成功",
                    type: 'success',
                });
                //关闭弹窗
                this.flag=false;
                //将token和登录用户信息保存在本地
                localStorage.setItem("token", resp.data.data.token)
                localStorage.setItem("user", JSON.stringify(resp.data.data.user));
                this.$router.push('/');
            });
        },
        //发送验证码
        sendEmail() {
            if (this.loginForm.userId == '') {
                this.$message({
                    showClose: true,
                    message: "请输入账号！",
                    type: 'error',
                });
                return;
            }
            this.$axios.post('user/sendEmail/' + this.loginForm.userId).then(resp => {
                this.$message({
                    showClose: true,
                    message: '发送成功',
                    type: 'success',
                });
                setInterval(this.updateTime, 1);
                this.isCode = false;
            });
        },
        updateTime() {
            if (this.time > 0) {
                this.time -= 0.004;
            } else {
                this.isCode = true
                this.time=60
            }
        },
    },
    mounted() {
        this.createCode();
    },
    computed: {
        t_Minutes() {
            const minutes = Math.floor(this.time / 60);
            return minutes.toString().padStart(2, '0');
        },
        t_Seconds() {
            const seconds = Math.floor(this.time % 60);
            return seconds.toString().padStart(2, '0');
        },
        t_Milliseconds() {
            const milliseconds = Math.floor((this.time % 1) * 100);
            return milliseconds.toString().padStart(2, '0');
        },
    },
    // created() {
    //     document.title = '登录';
    // }

}
</script>

<style scoped>
/deep/ .el-input__inner {
    background-color: rgba(255, 255, 255, 0.247);
}

/deep/ .el-dialog__body {
    background-color: rgb(246, 246, 246);
}

/deep/ .el-dialog__header {
    background-color: rgb(246, 246, 246);
}
</style>
