<template>
  <div style="width:100%;height:100%;">
    <div class="main">
      <div style="width:100%;height:55%;overflow: hidden;">
        <img :src="bgImg" width="100%" height="100%" style="object-fit: cover;" />
      </div>
      <div style="width:90%;height:70px;position: absolute;margin-top:-340px;margin-left:10px;">
        <div style="float:left">
          <router-link to style="color:black;font-size:15px">我的</router-link>
        </div>
        <div style="float:right">
            <i class="el-icon-camera" style="font-size:20px" slot="reference" @click="show=!show"></i>
        </div><br/>
        <div v-show="show" style="float:right;width:50px;background:#FFF;border:1px solid #EBEEF5;box-shadow:0 0.133vw 0.8vw 0 rgba(0,0,0,.1);
        text-align:center;color:black;border-radius:0.267vw;margin-right:10px;font-size:15px">
            <span>
                <a href="#">
                    视频
                </a>    
            </span><br/>
            <span>
                <a href="#" @click="toImgPen">
                    照片
                </a>
            </span>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Pen",
  data() {
    return {
      bgImg: "", //背景图片
      show:false
    };
  },
  methods: {
    //去往发照片盆友圈页面
    toImgPen(){
        this.$router.push({ name: "imgPen" });
    }
  },
  mounted() {
    this.bgImg = localStorage.getItem("img");
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color:black;
}

.customPopper {
    width: 40px!important;
}

.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* 整个滚动条 */
.main::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}

/* 滚动条里面滑块 */
.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>