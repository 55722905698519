<template>
    <div style="width:100%;height:100%">
        <div style="width: 100%;height:50px;border-bottom:0.2px black solid">
            <span style="font-size:25px;float:left;margin-left:15px;margin-top:10px"><b>申请</b></span>
        </div>
        <div class="cla">
            <el-collapse v-model="activeNames" @change="handleChange">
                <div v-for="(apply, index) in friendApplyList" :key="index">
                    <el-collapse-item :name="index">
                        <template slot="title">
                            <div style="width:50px;height:40px;margin-left:10px;float:left;">
                                <img :src="apply.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                            </div>
                            <div style="width: 200px;height:10px;float:left;margin-top:-60px;margin-left:10px;">
                                {{ apply.createTime }}
                            </div>
                            <div style="width: 100%;float:left;margin-left:-170px;font-size:15px;margin-top:20px">
                                <!--我是申请人-->
                                <span style="font-size: 15px;float:left;margin-left:10px;width:95%"
                                    v-if="apply.friendApplyId == user.userId">
                                    {{ apply.isFriendApplyName }}
                                    <div style="float:right;margin-top:-10px">
                                        <!--发送好友申请-->
                                        <span v-if="apply.status == '0'" style="color:goldenrod;">已发送,等待对方通过</span>
                                        <!--通过-->
                                        <span v-if="apply.status == '1'" style="color:chartreuse;">对方已通过</span>
                                        <!--拒绝-->
                                        <span v-if="apply.status == '2'" style="color:orangered;">对方已拒绝</span>
                                    </div>
                                </span>
                                <!--我是申请接收人-->
                                <span style="font-size: 15px;float:left;margin-left:10px;width:95%;"
                                    v-else-if="apply.isFriendApplyId == user.userId">
                                    {{ apply.friendApplyName }}
                                    <div style="float:right;margin-top:-10px">
                                        <!--接收好友申请-->
                                        <span v-if="apply.status == '0'">
                                            <el-button size="small" type="success" @click="to(apply.friendApplyId,apply.friendApplyName)">通过</el-button>
                                            <el-button size="small" type="danger" @click="not(apply.friendApplyId)">拒绝</el-button>
                                        </span>
                                        <!--通过-->
                                        <span v-if="apply.status == '1'" style="color:chartreuse;">已添加</span>
                                        <!--拒绝-->
                                        <span v-if="apply.status == '2'" style="color:orangered;">已拒绝</span>
                                    </div>
                                </span>
                            </div>
                        </template>
                        <div style="width:30%;height:32%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                            <img :src="apply.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <!--我是申请人-->
                        <div style="float: left;font-size:20px;margin-left:15px" v-if="apply.friendApplyId == user.userId">
                            <span>昵称：{{ apply.isFriendApplyName }}</span><br />
                            <span>账号：{{ apply.isFriendApplyId }}</span>
                        </div>
                        <!--我是申请接收人-->
                        <div style="float: left;font-size:20px;margin-left:15px"
                            v-if="apply.isFriendApplyId == user.userId">
                            <span>昵称：{{ apply.friendApplyName }}</span><br />
                            <span>账号：{{ apply.isFriendApplyId }}</span>
                        </div>
                    </el-collapse-item>
                </div>
            </el-collapse>
        </div>
    </div>
</template> 

<script>
export default {
    name: 'New',
    data() {
        return {
            user: {
                userId: "",
                userName: ""
            },
            activeNames: ['1'],
            friendApplyList: [
            ]
        }
    },
    methods: {
        //打开特定折叠面板
        handleChange(val) {
            
        },
        getList(){
            var apply={
                friendApplyId:this.user.userId
            }
            this.$axios.post('friendApply/selecApplytList', apply).then(resp => {
                this.friendApplyList = resp.data.data;
            });
        },
        //通过
        to(friendApplyId,friendApplyName){
            var apply = {
                friendApplyId: friendApplyId,
                friendApplyName:friendApplyName,
                isFriendApplyId: this.user.userId,
                isFriendApplyName:this.user.userName,
                decide: '1'
            }
            this.$axios.post('friendApply/applyMessage', apply).then(resp => {
                this.$message({
                    showClose: true,
                    message: resp.data.message,
                    type: 'success',
                });
                //刷新数据
                this.getList();
                //向父路由传值
                this.$emit('func',resp.data)
            });
            var m={
                messageId:this.user.userId,
                isMessageId:friendApplyId,
                news:'false'
            }
            //向双方更新来聊天框以及消息
            this.$ws.ws.send(JSON.stringify(m));
        },
        //拒绝
        not(friendApplyId){
            var apply = {
                friendApplyId: friendApplyId,
                isFriendApplyId: this.user.userId,
                decide: '2'
            }
            this.$axios.post('friendApply/applyMessage', apply).then(resp => {
                this.$message({
                    showClose: true,
                    message: resp.data.message,
                    type: 'success',
                });
                //刷新数据
                this.getList();
                //向父路由传值
                this.$emit('func',resp.data)
            });
        }
    },
    mounted() {
        //获得保存在本地的登录用户数据
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getList();
    }
}
</script>
<style scoped>

/deep/ .el-collapse-item__header{
    background-Color:rgba(255, 255, 255, 0.247);
}

.cla {
    width: 100%;
    height: 90%;
    float: left;
    overflow-y: auto;
}

/* 整个滚动条 */
.cla::-webkit-scrollbar {
    width: 8px;
}

/* 滚动条里面滑块 */
.cla::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: skyblue;
}

/*滚动条里面轨道*/
.cla::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background: #eee;
}
</style>