<template>
    <div class="demo" style="width:100%;height:100%">
        <div style="width: 100%;height:6%;border-bottom:0.5px black solid">
            <span style="font-size:20px;float:left;margin-left:20px;" @click="drawer = true">
                {{ group.name }}
            </span>
            <span style="font-size: 20px;float:right;" @click="drawer = true">
                <i class="el-icon-s-fold"></i>
            </span>
        </div>
        <!--群聊详情右侧弹出层-->
        <el-drawer :visible.sync="drawer" :with-header="false" :modal="false" size="35%" @open="openG">
            <div style="height:6%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                <span style="margin-left:10px;font-weight:bolder">群信息栏</span>
            </div>
            <div class="members">
                <div style="width:60px;height:60px;float: left;margin-left:15px;margin-top:10px"
                    v-for="member in memberList">
                    <div style="width:55px;height:50px;float:left;overflow: hidden;">
                        <img :src="member.groupUrl" width="100%" height="100%" style="object-fit: cover;" />
                    </div>
                    <span style="margin-left:8px;font-size: 10px;">{{ member.nickName | jiequName }}</span>
                </div>
            </div>
            <div style="height:2%;width:100%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;"></div>
            <div style="height:12%;width:100%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                <div style="height:90%;width:95%;margin-left:10px;margin-top:10px" v-if="qz.createGroupId == my.memberId">
                    <span style="color: black;">群聊名称</span><br />
                    <input type="text" v-model="qz.groupName" @blur="updateGroupName"
                        style="width:80%;height:20px;margin-top:5px" />
                </div>
                <div style="height:90%;width:95%;margin-left:10px;margin-top:10px" v-else>
                    <span style="color: black;">群聊名称</span><br />
                    <span style="color:rgb(109, 107, 107);">{{ qz.groupName }}</span>
                </div>
            </div>
            <div style="height:12%;width:100%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                <div style="height:90%;width:95%;margin-left:10px;margin-top:10px">
                    <span>备注</span><br />
                    <input type="text" v-model="my.groupNotes" @blur="updateGroupNotes"
                        style="width:80%;height:20px;margin-top:5px" />
                </div>
            </div>
            <div style="height:12%;width:100%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                <div style="height:90%;width:95%;margin-left:10px;margin-top:10px">
                    <span>我在本群的昵称</span><br />
                    <input type="text" v-model="my.nickName" @blur="updateNickName"
                        style="width:40%;height:20px;margin-top:5px" />
                </div>
            </div>
        </el-drawer>
        <!--聊天框-->
        <div class="cla" ref="contextH" @scroll="topList">
            <div style="width:100%;height:15px;font-size:12px;text-align:center" v-if="flag == 3">
                我也是有底线的！！！
            </div>
            <div style="width:100%;height:15px;font-size:12px;text-align:center" v-if="flag == 2">
                <i class="el-icon-loading"></i>
            </div>
            <div style="width:100%" v-for="ms in messageList">
                <div style="width:100%;float:left;margin-top:10px"
                    v-if="ms.groupId == group.id && ms.groupMessageId != user.userId">
                    <div style="width: 150px;height:20px;margin: auto;font-size:12px;text-align:center;">
                        {{ ms.dateTime }}
                    </div>
                    <div style="width: 60%;">
                        <div style="width:40px;height:20px;margin-left:10px;overflow: hidden;"
                            v-if="ms.groupMessageId == qz.createGroupId">
                            <img src="../imgs/111.jpg" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="width:40px;height:40px;margin-left:10px;float:left;overflow: hidden;">
                            <img :src="ms.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="width:75%;float:left;margin-left:5px">
                            <div style="height:15px;width:100%;float:left;font-size:10px">
                                {{ ms.name }}
                            </div>
                            <div style="min-height:20px;float:left;list-style: none;display: block;word-break: break-all;background-color:white;
                                border-radius: 5px;padding-left:5px;padding-top:10px;padding-right:5px;padding-bottom:10px"
                                v-if="ms.status == '0'">
                                <span v-html="ms.context"></span>
                            </div>
                            <div style="min-height:20px;float:left;list-style: none;display: block;word-break: break-all;
                                border-radius: 5px;padding-left:5px;padding-top:10px;padding-right:5px;padding-bottom:10px"
                                v-else>
                                <a href="#" @click="openImg(ms.context, ms.status)" v-if="ms.status == '1'">
                                    <img :src="ms.context.split('_x_')[2]" width="100%" height="100%"
                                        style="object-fit: cover;" />
                                </a>
                                <video style="object-fit: cover" :src="ms.context.split('_x_')[2]" width="95%" height="100%"
                                    preload="auto" showPlay="true" controls controlslist="nodownload"
                                    v-if="ms.status == '2'">
                                </video>
                                <div style="width:25px;height:25px;float:right" v-if="ms.status == '2'">
                                    <a href="#" @click="download(ms.context.split('_x_')[0], ms.context.split('_x_')[1])">
                                        <i class="el-icon-download" style="font-size:25px;color:black;"></i>
                                    </a>
                                </div>
                                <div style="width: 220px;height:70px;background-color:white;"
                                    v-if="ms.status != '0' && ms.status != '1' && ms.status != '2'">
                                    <div
                                        style="width:60px;height:50px;margin-left:5px;float:left;margin-top:6px;overflow: hidden;">
                                        <img src="../imgs/icon/icon-word.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '3'" />
                                        <img src="../imgs/icon/icon-excel.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '4'" />
                                        <img src="../imgs/icon/icon-pdf.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '5'" />
                                        <img src="../imgs/icon/icon-txt.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '6'" />
                                        <img src="../imgs/icon/icon-qi.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-else />
                                    </div>
                                    <div style="float: left;margin-top:10px;font-size:15px;">
                                        {{ ms.context.split('_x_')[0] | jiequ }}
                                    </div><br /><br />
                                    <div style="float: left;font-size:10px">
                                        {{ ms.context.split('_x_')[3] }}
                                    </div>
                                    <div style="float:right;font-size:20px;text-decoration:none;">
                                        <a href="#"><i class="el-icon-download" style="color:black"
                                                @click="download(ms.context.split('_x_')[0], ms.context.split('_x_')[1])"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width:100%;float:left;margin-top:10px"
                    v-if="ms.groupId == group.id && ms.groupMessageId == user.userId">
                    <div style="width: 150px;height:20px;margin: auto;font-size:12px;text-align:center;">
                        {{ ms.dateTime }}
                    </div>
                    <div style="width:98%;margin-top:6px;">
                        <div style="height:20px;width:100%;" v-if="ms.groupMessageId == qz.createGroupId">
                            <div style="width:40px;height:20px;float:right;overflow: hidden;">
                                <img src="../imgs/111.jpg" width="100%" height="100%" style="object-fit: cover;" />
                            </div>
                        </div>
                        <div style="width:40px;height:40px;float:right;overflow: hidden;">
                            <img :src="ms.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="width:45%;float:right;margin-right:5px;">
                            <div style="min-height:20px;float:right;list-style: none;display: block;word-break: break-all;background-color:rgb(155, 255, 101);
                                border-radius: 5px;padding-left:5px;padding-top:10px;padding-right:5px;padding-bottom:10px"
                                v-if="ms.status == '0'">
                                <span v-html="ms.context"></span>
                            </div>
                            <div style="min-height:20px;float:right;list-style: none;display: block;word-break: break-all;
                                border-radius: 5px;padding-left:5px;padding-top:10px;padding-right:5px;padding-bottom:10px"
                                v-else>
                                <a href="#" @click="openImg(ms.context, ms.status)" v-if="ms.status == '1'">
                                    <img :src="ms.context.split('_x_')[2]" width="100%" height="100%"
                                        style="object-fit: cover;" />
                                </a>
                                <video style="object-fit: cover;float:right" :src="ms.context.split('_x_')[2]" width="95%"
                                    height="100%" preload="auto" showPlay="true" controls controlslist="nodownload"
                                    v-if="ms.status == '2'">
                                </video>
                                <div style="width:25px;height:25px;float:left" v-if="ms.status == '2'">
                                    <a href="#" @click="download(ms.context.split('_x_')[0], ms.context.split('_x_')[1])">
                                        <i class="el-icon-download" style="font-size:25px;color:black;"></i>
                                    </a>
                                </div>
                                <div style="width: 220px;height:70px;background-color:white;"
                                    v-if="ms.status != '0' && ms.status != '1' && ms.status != '2'">
                                    <div
                                        style="width:60px;height:50px;margin-left:5px;float:left;margin-top:6px;overflow: hidden;">
                                        <img src="../imgs/icon/icon-word.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '3'" />
                                        <img src="../imgs/icon/icon-excel.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '4'" />
                                        <img src="../imgs/icon/icon-pdf.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '5'" />
                                        <img src="../imgs/icon/icon-txt.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-if="ms.status == '6'" />
                                        <img src="../imgs/icon/icon-qi.png" width="95%" height="95%"
                                            style="object-fit: cover;" v-else />
                                    </div>
                                    <div style="float: left;margin-top:10px;font-size:15px;">
                                        {{ ms.context.split('_x_')[0] | jiequ }}
                                    </div><br /><br />
                                    <div style="float: left;font-size:10px">
                                        {{ ms.context.split('_x_')[3] }}
                                    </div>
                                    <div style="float:right;font-size:20px;text-decoration:none;">
                                        <a href="#"><i class="el-icon-download" style="color:black"
                                                @click="download(ms.context.split('_x_')[0], ms.context.split('_x_')[1])"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--上传文件弹出层-->
        <div v-show="show"
            style="width:300px;height:220px;position: absolute;margin-top:-250px;margin-left:40px;background-color:rgb(236, 235, 235)">
            <div style="width: 100%;height:100%" v-if="isLoading == false">
                <i class="el-icon-loading" style="font-size:40px;line-height: 190px;margin-left:120px"></i>
            </div>
            <div style="width: 100%;height:100%" v-if="isLoading == true">
                <div style="width: 100%;height:38%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                    <div style="margin-left:10px;margin-top:10px">
                        <span>发送给:</span><br />
                        <div style="width:98%;height:70%;margin-top:5px">
                            <div style="width:40px;height:40px;float:left;overflow: hidden;">
                                <img :src="qz.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                            </div>
                            <div style="float:left;margin-left:5px;margin-top:5px">
                                {{ group.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;height:30%;border-bottom: 1px rgba(0, 0, 0, 0.1) solid;">
                    <div style="width:50px;height:50px;float:left;overflow: hidden;margin-top:5px;margin-left:3px">
                        <img :src="uploadFile.cattachUploadPath" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '1'" />
                        <img src="../imgs/icon/video.png" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '2'" />
                        <img src="../imgs/icon/icon-word.png" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '3'" />
                        <img src="../imgs/icon/icon-excel.png" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '4'" />
                        <img src="../imgs/icon/icon-pdf.png" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '5'" />
                        <img src="../imgs/icon/icon-txt.png" width="100%" height="100%" style="object-fit: cover;"
                            v-if="uploadFile.type == '6'" />
                        <img src="../imgs/icon/icon-qi.png" width="100%" height="100%" style="object-fit: cover;" v-else />
                    </div>
                    <div style="float:left;margin-left:5px;margin-top:10px;width:240px;">
                        {{ uploadFile.cattachName | jiequ }}
                    </div>
                    <div style="float:left;margin-left:5px;font-size:10px;margin-top:5px;">
                        {{ uploadFile.cattachSize }}
                    </div>
                </div>
                <div style="width: 100%;height:20%;">
                    <div style="margin-left: 40px;margin-top:7px">
                        <el-button size="medium" style="background-color:rgb(6, 201, 100);" type="success"
                            @click="toFile">确定</el-button>
                        <el-button size="medium" style="margin-left:20px;color:rgb(6, 201, 100);background-color:white"
                            @click="showFalse">取消</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!--输入框-->
        <div style="width:100%;height:15%;">
            <div style="width: 100%;height:35%;float:left;font-size:22px;">
                <div style="float:left;margin-left:10px;margin-top:-2px;width:22px;opacity: 0.6;">
                    <el-popover ref="popoveraite" placement="top-start" width="280" trigger="click">
                        <a href="#" slot="reference" style="text-decoration: none;">
                            <span style="font-size:18px">&#128512;</span>
                        </a>
                        <div class="emoji" style="width:100%;height:200px;overflow-y: auto;">
                            <a href="#" style="text-decoration: none;font-size:20px" v-for="emo in emojiList">
                                <span v-html="emo.emoji" @click="getConcat(emo.emoji)"></span>
                            </a>
                        </div>
                    </el-popover>
                </div>
                <div style="float:left">
                    <el-upload class="avatar-uploader" :action="uploadPath" :show-file-list="false"
                        :on-success="filehandleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <i class="el-icon-folder-add" style="margin-left:10px;color:rgb(125, 123, 123);"></i>
                    </el-upload>
                </div>
            </div>
            <div style="width:100%;float:left">
                <el-input type="textarea" :rows="2" v-model.trim="textarea" style="width:85%;" @focus="setBorderNum"
                    @keyup.enter.native="to">
                </el-input>
                <el-button size="medium" style="margin-top: 10px;margin-right:5px;float:right;color:rgb(6, 201, 100)"
                    @click="to">发送</el-button>
            </div>
        </div>
        <!--图片详情弹出层-->
        <el-dialog :visible.sync="dialoVisible" append-to-body :close-on-click-modal="false">
            <div slot="title">
                <a href="#" @click="download(imgDetail.result, imgDetail.fileName)">
                    <i class="el-icon-download" style="font-size:20px;color:black;"></i>
                </a>
            </div>
            <img :src="imgDetail.url" width="100%" height="100%" style="object-fit: cover;margin-top:-30px"
                v-if="imgDetail.type == '1'" />
            <video style="object-fit: cover;" :src="imgDetail.url" width="100%" height="100%" preload="auto" showPlay="true"
                v-if="imgDetail.type == '2'">
            </video>
        </el-dialog>
    </div>
</template> 

<script>
import icon from '@/util/icon.js'
export default {
    name: 'Group',
    watch: {
        //监听相同路由下参数变化的时候，从而实现异步刷新
        '$route.query.id'(newValue, oldValue) {
            //做一些路由变化的响应
            //打开加载动画
            //关闭websocket连接
            if (this.websock) {
                this.websock.close();
                this.websock = null;
            }
            //重新获取数据
            this.getSeries(this.$route.query.id, this.$route.query.name)
            // this.scrollToBottom();
        },
    },
    data() {
        return {
            user: {},
            websock: null,
            textarea: '',
            messageList: [],//消息内容
            status: true,//判断是滚动查询数据还是发送消息
            pageNum: 1,
            pageSize: 50,
            message: [],//返回分页消息体
            flag: 1,//是否继续滚动 1-可以滚动查询 2-查询数据时的样式  3-停止滚动查询
            h: 0,//保存聊天框高度
            group: {
                id: '',
                name: ''
            },//保存路由传值的群编号和群名称
            qz: {},//群主
            drawer: false,//右侧弹窗
            memberList: [],//所有群成员
            my: {},//我在本群的详细信息
            uploadPath: '',//文件上传地址
            show: false,//文件弹出层显示隐藏
            uploadFile: {},//发送文件消息，上传对象
            isLoading: false,//加载动画
            dialoVisible: false,//图片、视频详情弹出层
            imgDetail: {},//图片、视频详情信息
            emojiList: [],//emoji列表
        }
    },
    methods: {
        //自定义的数据改变方法
        getSeries(id, name) {
            this.pageNum = 1;
            this.h = 0;
            this.flag = 1;
            this.status = true;
            //获得保存在本地的登录用户数据
            this.user = JSON.parse(localStorage.getItem('user'));
            //获取路由传值
            this.group.id = id;
            this.group.name = name;
            this.status = true;
            //文件上传地址
            this.uploadPath = this.$url.http + "/minio/upload"
            //查看群主
            this.getGroup();
            //初始化webscoket
            this.initWebSocket();
            //从vuex中获得emoji列表数据
            //this.emojiList = this.$store.state.emoji
			this.emojiList = icon.iconList;
        },
        //群聊详细信息
        openG() {
            this.getMemberList();
            this.getMemberDetail();
        },
        //所有群成员信息
        getMemberList() {
            this.$axios.post('group/getMemberList', { 'groupId': this.group.id }).then(resp => {
                this.memberList = resp.data.data;
            });
        },
        //查询我在本群的详细信息
        getMemberDetail() {
            this.$axios.post('group/getMemberDetail', { 'groupId': this.group.id }).then(resp => {
                this.my = resp.data.data;
            });
        },
        //查看群主
        getGroup() {
            this.$axios.post('group/getGroup', { 'groupId': this.group.id }).then(resp => {
                this.qz = resp.data.data;
            });
        },
        initWebSocket() { //初始化weosocket
            var url = this.$url.path + "/groupwebsocket/" + this.group.id + "/" + this.user.userId;
            this.websock = new WebSocket(url);
            this.websock.onmessage = this.websocketonmessage;
        },
        websocketonopen() { //连接建立之后执行send方法发送数据

        },
        websocketonerror() {//连接建立失败重连
            this.initWebSocket();
        },
        websocketonmessage(e) { //数据接收
            var message = JSON.parse(e.data);
            //说明历史聊天数据加载完成，不在调用接口
            if (message.length == 0) {
                this.flag = 3;
                //分页归零
                this.pageNum = 1;
            } else if (this.status == true && message.length > 0) {
                this.messageList = message
                this.flag = 1;
                //分页归零
                this.pageNum = 1;
                //接收websocket消息之后
                this.scrollToBottom();
                //最后一条消息发送人是自己才改变滚动条位置到最底部
                // if(this.messageList[this.messageList.length-1].messageId===this.user.userId){

                // }
            } else if (this.status == false && message.length > 0) {//滚动分页查询数据
                //设置分页和消息数据
                this.messageList = message.concat(this.messageList);
                this.flag = 1;
                //设置滚动条位置为上一次滚动消息的高度
                this.setH();
            }

        },
        websocketsend(Data) {//数据发送
            if (Data == undefined || Data == '' || Data == null) {
                this.websock.send(null);
            } else {
                this.websock.send(Data);
            }

        },
        websocketclose(e) {  //关闭
            this.websock.close()
            this.messageList = [];
        },
        //回车发送消息
        toEntry() {
            console.log("回车事件")
        },
        //发送消息
        to() {
            if (!this.textarea || this.textarea == '' || this.textarea.length == 0) {
                return;
            }
            //发送消息
            var message = {
                groupId: this.group.id,
                groupMessageId: this.user.userId,
                context: this.textarea,
            }
            this.websocketsend(JSON.stringify(message));
            this.textarea = ''
            this.status = true
            //向群聊所有成员发送消息
            var m = {
                messageId: this.user.userId,
                isMessageId: this.group.id,
                news: 'group'
            }
            var then = this;
            setTimeout(function () {
                then.getNum(JSON.stringify(m));
            }, 10);
        },
        //滚动条处于底部
        scrollToBottom() {
            // this.$nextTick 将回调延迟到下次DOM更新循环之后执行。在修改数据之后立即使用它，然后等待DOM更新
            this.$nextTick(() => {
                // dom 元素更新后执行滚动条到底部 否则不生效
                let scrollElem = this.$refs.contextH;
                // scrollElem.scrollTop = scrollElem.scrollHeight
                scrollElem.scrollTo({
                    top: scrollElem.scrollHeight,
                    // behavior: 'smooth'//加上这个会有滚动动画
                });
                this.h = scrollElem.scrollHeight;//保存整个div的高度
            });
        },
        //设置滚动条位置
        setH() {
            this.$nextTick(() => {
                // dom 元素更新后执行滚动条到底部 否则不生效
                let scrollElem = this.$refs.contextH;
                // scrollElem.scrollTop = scrollElem.scrollHeight
                scrollElem.scrollTo({
                    top: scrollElem.scrollHeight - this.h,
                    // behavior: 'smooth'//加上这个会有滚动动画
                });
            });
        },
        //向上滚动查询数据
        topList() {
            //监听滚动条高度
            let toTop = this.$refs.contextH.scrollTop;
            if (toTop == 0 & this.h != 0) {//当滚动条位置到达聊天框最上方时查询历史数据
                //历史聊天已经查询完
                if (this.flag == 3) {
                    return;
                }
                this.h = this.$refs.contextH.scrollHeight;//保存整个div的高度
                this.status = false;//不让消息内容层居于最底部,实现查询历史聊天数据
                this.flag = 2;//实现假的加载信息效果

                var then = this;
                setTimeout(function () {
                    then.pageNum = then.pageNum + 1
                    var message = {
                        groupId: then.group.id,
                        groupMessageId: then.user.userId,
                        pageNum: then.pageNum,
                        pageSize: then.pageSize
                    }
                    then.websocketsend(JSON.stringify(message))
                }, 500);
            }
        },
        //重新查询未读消息数量
        getNum(e) {
            //向聊天框websocket发送一条重新查询聊天框未读数量
            this.$ws.ws.send(e);
        },
        //输入框获得焦点事件，将所有未读修改为已读
        setBorderNum() {
            //将未读消息修改为已读
            var m = {
                messageId: this.user.userId,
                isMessageId: this.group.id,
                news: 'click'
            }
            this.getNum(JSON.stringify(m));
        },
        //修改我在本群的昵称
        updateNickName() {
            if (this.my.nickName.length > 6) {
                this.$message({
                    showClose: false,
                    message: "群昵称最多6位数！",
                    type: 'error',
                });
                return;
            }
            var member = {
                "groupId": this.group.id,
                "memberId": this.my.memberId,
                "nickName": this.my.nickName
            }
            this.$axios.post('group/updateNotes', member).then(resp => {
                this.getMemberList();//刷新群成员信息
                this.getMemberDetail();//查询我在群聊内的详细信息
            });
        },
        //修改群备注
        updateGroupNotes() {
            if (this.my.groupNotes.length > 12) {
                this.$message({
                    showClose: false,
                    message: "群昵称最多12位数！",
                    type: 'error',
                });
                return;
            }
            var member = {
                "groupId": this.group.id,
                "memberId": this.my.memberId,
                "groupNotes": this.my.groupNotes
            }
            this.$axios.post('group/updateNotes', member).then(resp => {
                this.getMemberDetail();//查询我在群聊内的详细信息
                //刷新群聊左上角群聊名称
                this.group.name = this.my.groupNotes;
                //向websocket发送消息刷新聊天框群聊名称
                var m = {
                    messageId: this.user.userId,
                    isMessageId: this.group.id,
                    news: ""
                }
                this.$ws.ws.send(JSON.stringify(m));
            });
        },
        //群主修改群名称
        updateGroupName() {
            if (this.qz.groupName.length > 12) {
                this.$message({
                    showClose: false,
                    message: "群名称最多12位数！",
                    type: 'error',
                });
                return;
            }
            this.$axios.post('group/updateGroupName', { "groupId": this.group.id, "groupName": this.qz.groupName }).then(resp => {
                //刷新群名称信息
                this.getGroup();
            });
        },
        //文档上传接口返回函数
        filehandleAvatarSuccess(res, file) {
            if (file.response.code == 200) {
                this.uploadFile = file.response.data
                this.isLoading = true
            } else {
                this.$message.error(file.response.message);
                this.show = false;
            }

        },
        //文件上传获得文件
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return;
            }
            this.show = true;
            this.isLoading = false;
        },
        //文件消息发送接口
        toFile() {
            //设置文件消息体
            var str = this.uploadFile.cattachName + '_x_' + this.uploadFile.cattachUploadName + '_x_' + this.uploadFile.cattachUploadPath + '_x_' + this.uploadFile.cattachSize
            //发送消息
            var message = {
                groupId: this.group.id,
                groupMessageId: this.user.userId,
                context: str,
                status: this.uploadFile.type //指定消息类型
            }
            this.websocketsend(JSON.stringify(message));
            this.status = true  //设置true，确认是发送消息
            //向群聊所有成员发送消息
            var m = {
                messageId: this.user.userId,
                isMessageId: this.group.id,
                news: 'group'
            }
            var then = this;
            setTimeout(function () {
                then.getNum(JSON.stringify(m));
            }, 10);
            //关闭文件弹出层
            this.show = false;
        },
        //关闭文件弹出层,同时将刚刚上传的文件从文件服务器删除
        showFalse() {
            this.$axios.post('minio/remove', { cattachUploadName: this.uploadFile.cattachUploadName }).then(resp => {
                this.show = false;
            });
        },
        //公共文件下载
        download(result, fileName) {
            const loading = this.$loading({
                lock: true,
                text: '正在下载',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                target: document.querySelector(".demo")//loading需要覆盖的DOM节点，默认为body
            });
            this.$axios.get('minio/download', { responseType: 'blob', params: { "fileName": fileName } }).then(resp => {
                if (resp.type === 'application/json') {
                    this.$message.error("未知异常，下载失败！");
                    //关闭加载
                    loading.close()
                } else {
                    let url = window.URL.createObjectURL(new Blob([resp]));
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download", decodeURI(result));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link)
                    //关闭加载
                    loading.close()
                }
            })
        },
        //图片详情弹出层
        openImg(value, type) {
            this.imgDetail.result = value.split('_x_')[0];
            this.imgDetail.fileName = value.split('_x_')[1];
            this.imgDetail.url = value.split('_x_')[2];
            this.imgDetail.type = type;
            this.dialoVisible = true;
        },
        //点击emoji字符拼接输入框内容
        getConcat(value) {
            this.textarea = this.textarea + value
            //关闭emoji弹出层
            this.$refs['popoveraite'].doClose()
        }
    },
    mounted() {
        //关闭websocket连接
        if (this.websock) {
            this.websock.close();
            this.websock = null;
        }
        this.getSeries(this.$route.query.id, this.$route.query.name)
    },
    filters: {
        jiequ(value) {
            if (!value) return '';
            var arr1 = value.split(".")[0];
            var arr2 = value.split(".")[1];
            if (arr1.length > 7) {
                return arr1.slice(0, 5) + '...' + arr1.slice(arr1.length - 1) + '.' + arr2
            }
            return value
        },
        jiequName(value) {
            if (!value) return '';
            if (value.length > 3) {
                return value.slice(0, 3) + '..'
            }
            return value
        }
    },
}
</script>
<style scoped>
/**输入框透明背景 */
/deep/ .el-textarea__inner {
    background-color: rgba(255, 255, 255, 0.247);
    color: black;
}

/**文本输入框边框调整 */
/deep/ .el-textarea__inner {
    border: 1px rgba(255, 255, 255, 0.247) solid;
    resize: none;
    /**去除文本域右下角三点 */
}

/deep/ .el-button {
    background-color: rgba(255, 255, 255, 0.247);
}


.cla {
    width: 100%;
    height: 78%;
    border-bottom: 0.5px black solid;
    overflow-y: auto;
}

/* 整个滚动条 */
.cla::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
}

/* 滚动条里面滑块 */
.cla::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.cla::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

/deep/ .el-drawer__body {
    background-color: rgba(211, 201, 201, 0.247);
}

.members {
    width: 100%;
    height: 40%;
    overflow-y: auto;
}

/* 整个滚动条 */
.members::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
}

/* 滚动条里面滑块 */
.members::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.members::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

/* 整个滚动条 */
.emoji::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
}

/* 滚动条里面滑块 */
.emoji::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.emoji::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}
</style>