<template>
  <div style="width: 100%; height: 100%">
    <div class="left">
      <p style="margin-left:10%;margin-top:9px">
        <el-popover placement="right-bottom" width="200" trigger="hover" @hide="demo">
          <img :src="file.cattachUploadPath" width="50px" height="46px" style="object-fit: cover;" slot="reference" />
          <div :data="user">
            <a href="#" @click="dialoVisible = true">
              <img :src="file.cattachUploadPath" width="80px" height="70px" style="object-fit: cover;" />
            </a>
            <div>
              <span v-if="is == true">昵称：{{ user.userName }}</span>
              <span v-if="is == false">昵称：<input type="text" v-model="name" @blur="updateName" autofocus="autofocus"
                  style="width:80px" /></span>
              <i class="el-icon-edit" @click="isFlag"></i>
              <br />
              <span>账号：{{ user.userId }}</span>
            </div>
          </div>
        </el-popover>
      </p>
      <p style="margin-left:20%;"><a href="#" @click="toMessage" style="font-size:35px;">
          <el-badge :value="sum" :max="99" class="item" v-if="sum > 0">
            <i class="el-icon-chat-round" :style="t1 == true ? { color: 'rgb(155, 255, 101)' } : { color: 'white' }"></i>
          </el-badge>
          <el-badge v-if="sum == 0">
            <i class="el-icon-chat-round" :style="t1 == true ? { color: 'rgb(155, 255, 101)' } : { color: 'white' }"></i>
          </el-badge>
        </a>
      </p>
      <p style="margin-left:20%;"><a href="#" @click="toFriend" style="font-size:35px;">
          <i class="el-icon-user" :style="t2 == true ? { color: 'rgb(155, 255, 101)' } : { color: 'white' }"></i></a>
      </p>
      <!-- <p style="margin-left:20%;"><a href="#" @click="toPen" style="font-size:35px;">
          <i class="el-icon-orange" :style="t3 == true ? { color: 'rgb(155, 255, 101)' } : { color: 'white' }"></i></a>
      </p> -->
      <p style="margin-top:320px;margin-left:20%"><a href="#" @click="logout"><i class="el-icon-switch-button"
            style="font-size:35px;"></i></a></p>
    </div>
    <div class="right">
      <router-view />
    </div>
    <!--弹出层  @close="closeDialog"-->
    <el-dialog title="修改头像" :visible.sync="dialoVisible" append-to-body :close-on-click-modal="false" :show-close="false"
      width="400px">
      <div style="height:250px;width:320px;margin: auto;margin-top:-20px;overflow: hidden;"
        v-if="upload.cattachUploadPath != ''">
        <img :src="upload.cattachUploadPath" width="100%" height="100%" style="object-fit: cover;" />
      </div>
      <div style="height:250px;width:320px;margin: auto;margin-top:-20px;overflow: hidden;border:1px #8c939d solid"
        v-if="upload.cattachUploadPath == ''">
        <el-upload class="avatar-uploader" :action="uploadPath" :show-file-list="false" :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <i class="el-icon-plus avatar-uploader-icon" style="font-size:40px;color: #8c939d;width: 178px;height: 178px;
          line-height: 190px;margin-left:70px"></i>
        </el-upload>
      </div>
      <div style="border-radius: 10px;float:right;margin-top:-264px;position: relative;
                   font-size:20px;margin-right:14px">
        <a hred="#" style="color:black;cursor:pointer;" v-if="upload.cattachUploadPath != ''" @click="imgShow()">X</a>
      </div>
      <div style="margin-top:10px">
        <el-button size="medium" type="primary" @click="updateAvatar">确定</el-button>
        <el-button size="medium" style="margin-left:20px" @click="quxiao">取消</el-button>
      </div>
    </el-dialog>

    <!--视频通话-->
    <div style="width:400px;height:500px;position: relative;margin-left:250px;border:1px #DCDFE6 solid;background-color:white;z-index:2"
      v-if="show>showEnum.zero">
      <!--我发起通话请求我的展示页面-->
      <div style="position: absolute;font-size:12px;width:100%;text-align:center;bottom:0;margin-bottom:10px" v-if="show===showEnum.one">
        <span>等待对方接受邀请中...</span>
        <p></p>
        <el-button size="mini" @click="getCancel">取消</el-button>
      </div>
      <!--我发起通话请求对方的展示页面-->
      <div style="position: absolute;font-size:12px;width:100%;text-align:center;bottom:0;margin-bottom:10px" v-if="show===showEnum.two">
        <el-button size="mini" @click="getAccept">同意</el-button>
        <el-button size="mini" @click="getReject">拒绝</el-button>
      </div>
      <!--通话请求通过后的展示页面-->
      <div style="position: absolute;font-size:12px;width:100%;text-align:center;bottom:0;margin-bottom:10px" v-if="show===showEnum.three">
        <div style="width:100px;height:120px;position: absolute;right:0px">
          <video ref="myVideo" style="width:100%;height:100%" muted></video>
        </div>
        <div style="width:100%;height:455px;border:1px #DCDFE6 solid;">
          <video ref="friendVideo" style="width:100%;height:100%" muted></video>
        </div>
        <el-button size="mini" @click="getRingOff" style="margin-top:5px">挂断</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from "peerjs";
export default {
  name: "Main",
  data() {
    return {
      user: {
        userId: "",
        userName: "",
        userPass: "",
        qqCode: "",
        firendId: "",
        firendName: "",
        Nickname: "",
        isNot: "",
        createUser: "",
        createTime: "",
        updateUser: "",
        updateTime: ""
      },
      file: {},//文件对象
      is: true,
      name: '',
      dialoVisible: false,//弹出层
      upload: {},//备份文件对象
      imageUrl: '',
      t1: false,//消息页面状态
      t2: false,//好友页面状态
      t3: false,//盆友圈页面状态
      uploadPath: '',//文件上传接口地址
      sum: 0,//未读总数量
      msgEnum: {
        zero: 0,//接收方返回给发送方的消息来打开发送方的通信界面
        request: 1, //请求视频通话
        reject: 2, //拒绝视频通话
        accept: 3, //接受视频通话
        cancel: 4, //取消视频通话
        ringOff: 5, //正常挂断
        busy: 6, //忙碌挂断(对方不在线)
        under: 7 //对方正在通话中
      },
      showEnum: {
        zero: 0, //正常页面
        one: 1, //向对方发起通话请求页面
        two: 2, //接收对方通话请求页面
        three: 3 //同意通话请求，双方的展示页面
      },
      show: 0, //页面展示值
      statusEnum: {
        normal: 0, //正常状态
        proceed: 1 //通话中
      },
      status: 0, //状态值
      call: undefined,//通话对象
      account:undefined,//对方账号
    };
  },
  methods: {
    //退出
    logout() {
      this.$axios.get("/user/logout").then(resp => {
        this.$message({
          showClose: true,
          message: "退出登录",
          type: 'success',
        });
        //清除本地所有缓存
        localStorage.clear();
        //关闭全局ws连接
        this.$ws.ws.close();
        //关闭全局peer服务
        this.$peer.peer.close()
        //跳转登录页面
        this.$router.push("/login");
      });
    },
    //点击事件去往消息页面
    toMessage() {
      this.t1 = true;
      this.t2 = false;
      this.t3 = false;
      localStorage.setItem("click", "t1");
      this.$router.push({ name: "Message" });
    },
    //点击事件去往好友页面
    toFriend() {
      this.t1 = false;
      this.t2 = true;
      this.t3 = false;
      localStorage.setItem("click", "t2");
      this.$router.push("/friend");
    },
    //点击事件去往盆友圈页面
    toPen() {
      this.t1 = false;
      this.t2 = false;
      this.t3 = true;
      localStorage.setItem("click", "t3");
      this.$router.push("/friendPen");
    },
    //进入主页获得登录用户头像信息
    getAvatar() {
      this.$axios.post('user/getAvatar').then(resp => {
        var data = resp.data.data;
        this.file = data;
        //this.upload=data  会造成file和upload俩个对象地址引用一样，导致修改file的值同时upload的值也一样改变
        //当使用JSON.parse和JSON.stringify方法进行深层复制时，两个对象的引用并不相同，所以彼此之间的赋值操作并不会产生影响。
        //而当直接将一个对象赋值给另一个对象时，则是引用操作，因此两对象之间的赋值操作是互通的。
        this.upload = JSON.parse(JSON.stringify(data));
        //将登录用户头像信息保存在浏览器
        localStorage.setItem("url", this.file.cattachUploadPath);
        //获得emoji表情编码
        //this.getEmoji();
      });
    },
    //昵称输入框显示隐藏
    isFlag() {
      this.is = !this.is;
      //将用户原本的昵称赋值到输入框
      this.name = this.user.userName;
    },
    //登录用户信息弹出层隐藏事件
    demo() {
      this.is = true
    },
    //修改昵称
    updateName() {
      if (this.name.length > 6) {
        this.$message({
          showClose: false,
          message: "昵称最多6位数！",
          type: 'error',
        });
        return;
      }
      var user = {
        "userName": this.name
      }
      //修改用户信息成功之后，修改浏览器保存的用户信息
      this.$axios.post('user/updateName', user).then(resp => {
        //拿到浏览器保存的用户信息
        var user = JSON.parse(localStorage.getItem('user'));
        user.userName = this.name
        //重新赋值
        this.user = user;
        //覆盖之前的用户信息
        localStorage.setItem("user", JSON.stringify(user));
        //将状态返回成显示
        this.is = !this.is
      });
    },
    //上传和图片隐藏显示
    imgShow() {
      this.upload.cattachUploadPath = ''
    },
    //取消修改
    quxiao() {
      this.dialoVisible = false
      //重新备份
      this.upload = JSON.parse(JSON.stringify(this.file));
    },
    // closeDialog() {
    //   this.dialoVisible = false
    //   //重新备份
    //   this.upload = JSON.parse(JSON.stringify(this.file));
    // }
    //返回函数
    handleAvatarSuccess(res, file) {
      this.upload = file.response.data
    },
    //获得选择文件
    beforeAvatarUpload(file) {
      var arr = ['image/jpeg', 'image/png', 'image/jpg']
      let isJPG = false;
      for (let i in arr) {
        if (file.type === arr[i]) {
          isJPG = true;
          break;
        }
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //修改头像
    updateAvatar() {
      //如果文件对象路径和备份文件头像路径一致
      if (this.upload.cattachUploadPath === this.file.cattachUploadPath) {
        this.$message.error('请选择要上传的头像！');
        return;
      }
      //上传对象路径为空说明没有上传头像
      if (this.upload.cattachUploadPath === '') {
        this.$message.error('请先上传头像！');
        return;
      }
      this.$axios.post('user/updateAvatar', this.upload).then(resp => {
        //重新查询用户头像信息，且修改浏览器数据
        this.getAvatar();
        this.dialoVisible = false
      })
    },
    //获得emoji编码数据
    getEmoji() {
      this.$axios.post('user/getEmoji').then(resp => {
        //将数据保存在vuex中
        this.$store.commit('setEmoji', resp.data.data)
      })
    },
    //获得盆友圈背景图片
    getImg(){
      this.$axios.post('pen/getImg').then(resp => {
        localStorage.setItem("img", resp.data.data.cattachUploadPath);
      });
    },
    //连接peer服务，创建全局连接标识
    getConnect(){
      var peer = new Peer(this.user.userId.split('@qq.com')[0]);
      this.$peer.setPeer(peer)

      //连接事件
      this.$peer.peer.on("open", peerId => {
        // this.$message.success("连接成功！")
      });

      //监听被连接事件，接收消息
      this.$peer.peer.on("connection", connect => {
        //想建立连接的客户端
        var peerId = connect.peer;
        connect.on("data", data => {
          //对方发来的通话请求
          if (data == this.msgEnum.request) {
            // 判断是否已经有其他连接
            if (this.status == this.statusEnum.proceed) {
              //返回我已经有其他连接对象消息
              var connect = this.$peer.peer.connect(peerId);
              connect.on("open", () => {
                //向对方发送消息
                connect.send(this.msgEnum.under);
              });
            } else {
              // 没有其他连接对象,正常接收通话请求
              //修改页面展示状态
              this.show = this.showEnum.two;
              //状态变更为通话中
              this.status = this.statusEnum.proceed;
              //存储对方账号
              this.account=peerId
              //向发送方发送消息让其通话界面打开
              var connect = this.$peer.peer.connect(peerId);
              connect.on("open", () => {
                //向对方发送消息
                connect.send(this.msgEnum.zero);
              });
            }
          }else if(data == this.msgEnum.zero){
            //说明是接收方返回的信息让发送发打开通话页面
            //将页面展示修改
            this.show = this.showEnum.one;
            //状态变更为通话中
            this.status = this.statusEnum.proceed;
            //存储对方账号
            this.account=peerId
          }else if (data == this.msgEnum.cancel) {
            //对方取消通话请求
            this.$message.error("对方已取消");
            //页面恢复正常状态
            this.show = this.showEnum.zero;
            //状态变更为正常状态
            this.status = this.statusEnum.normal;
          } else if (data == this.msgEnum.accept) {
            //对方同意通话请求
            //页面为通话状态展示
            this.show = this.showEnum.three;
            //状态变更为通话中
            this.status = this.statusEnum.proceed;
          } else if (data == this.msgEnum.reject) {
            //对方拒绝通话请求
            this.$message.error("对方已拒绝");
            //页面恢复正常状态
            this.show = this.showEnum.zero;
            //状态变更为正常状态
            this.status = this.statusEnum.normal;
          } else if (data == this.msgEnum.ringOff) {
            //对方挂断通话
            this.$message.error("对方已挂断");
            //页面恢复正常状态
            this.show = this.showEnum.zero;
            //状态变更为正常状态
            this.status = this.statusEnum.normal;
            //将通道关闭
            this.close();
          } else if (data == this.msgEnum.under) {
            //对方正在通话中
            this.$message.error("对方正在通话中");
            //页面恢复正常状态
            this.show = this.showEnum.zero;
            //状态变更为正常状态
            this.status = this.statusEnum.normal;
          }
        });
      });

      //监听呼叫事件
      this.$peer.peer.on("call", call => {
        this.call = call;
        this.getUserMedia({ audio: true, video: true }).then(userMedia => {
          this.$refs.myVideo.srcObject = userMedia;
          this.$refs.myVideo.autoplay = true;
          this.$refs.myVideo.play();
          //相应给对方自己的媒体信息
          this.call.answer(userMedia);
          //监听对方传递的流信息
          this.call.on("stream", remoteStream => {
            this.$refs.friendVideo.srcObject = remoteStream;
            this.$refs.friendVideo.autoplay = true;
            this.$refs.friendVideo.play();
          });
          this.call.on("close", () => {
            this.$message.error("通话结束");
            this.close();
          });
          this.call.on("error", err => {
            this.$message.error("通话异常：" + err);
            this.close();
          });
        }).catch(err => {  
          this.$message.error("获取媒体流失败:"+err);  
          });
      });

      //监听异常信息
      this.$peer.peer.on("error", e => {
        var str = "" + e;
        if (str.indexOf("Lost connection to server") >= 0) {
          this.$message.error("连接视频服务失败");
          //页面恢复正常状态
          this.show = this.showEnum.zero;
          this.status = this.statusEnum.normal;
          return;
        }
        if (str.indexOf("not connect") >= 0) {
          this.$message.error("对方正在忙碌中");
          //页面恢复正常状态
          this.show = this.showEnum.zero;
          this.status = this.statusEnum.normal;
          return;
        }
        if (str.indexOf("is taken") >= 0) {
          this.$message.error("检测此账号在其他的地方连接，退出通话");
          //页面恢复正常状态
          this.show = this.showEnum.zero;
          this.status = this.statusEnum.normal;
          return;
        }
        //其他错误
        this.$message.error("浏览器版本不支持或版本过低！");
        //页面恢复正常状态
        this.show = this.showEnum.zero;
        this.status = this.statusEnum.normal;
      });
    },
    //通用发送消息方法
    getCall(msg) {
      //获得接受方的连接标识
      var receiveId = this.account;
      //建立与对方的连接
      var connect = this.$peer.peer.connect(receiveId);
      connect.on("open", () => {
        //向对方发送消息
        connect.send(msg);
      });
    },
    //取消通话请求
    getCancel() {
      this.getCall(this.msgEnum.cancel);
      //页面恢复正常状态
      this.show = this.showEnum.zero;
      //状态变更为正常状态
      this.status = this.statusEnum.normal;
    },
    //同意通话请求
    getAccept() {
      this.getCall(this.msgEnum.accept);
      //页面展示为通话中状态
      this.show = this.showEnum.three;
      //状态变更为通话中
      this.status = this.statusEnum.proceed;
      this.getStream();
    },
    //拒绝通话请求
    getReject() {
      this.getCall(this.msgEnum.reject);
      //页面恢复正常状态
      this.show = this.showEnum.zero;
      //状态变更为正常状态
      this.status = this.statusEnum.normal;
    },
    //挂断
    getRingOff() {
      this.getCall(this.msgEnum.ringOff);
      //页面恢复正常状态
      this.show = this.showEnum.zero;
      //状态变更为正常状态
      this.status = this.statusEnum.normal;
      //将通道关闭
      this.close();
    },
    //页面展示窗口
    getStream() {
      this.getUserMedia({ audio: true, video: true }).then(userMedia => {
        this.$refs.myVideo.srcObject = userMedia;
        this.$refs.myVideo.autoplay = true;
        this.$refs.myVideo.play();
        //传递流给对方客户端
        this.call = this.$peer.peer.call(this.account, userMedia);
        //监听对方传递的流信息
        this.call.on("stream", remoteStream => {
          this.$refs.friendVideo.srcObject = remoteStream;
          this.$refs.friendVideo.autoplay = true;
          this.$refs.friendVideo.play();
        });
        this.call.on("close", () => {
          this.$message.error("通话结束");
          this.close();
        });
        this.call.on("error", err => {
          this.$message.error("通话异常：" + err);
          this.close();
        });
      }).catch(err => {  
        this.$message.error("获取媒体流失败:"+err);  
      });
    },
    //浏览器打开麦克风和摄像头
    getUserMedia(constrains) {
      if (window.navigator.mediaDevices.getUserMedia) {
        return window.navigator.mediaDevices.getUserMedia(constrains);
      } else if (window.navigator.webkitGetUserMedia) {
        return window.navigator.webkitGetUserMedia(constrains);
      } else if (window.navigator.mozGetUserMedia) {
        return window.navigator.mozGetUserMedia(constrains);
      } else if (window.navigator.getUserMedia) {
        return window.navigator.getUserMedia(constrains);
      }else{
        this.$message.error("获取本地流失败，请设置浏览器访问权限！");
      }
    },
    close() {
      if (this.call) {
        //关闭通道
        this.call.close();
        this.call = undefined;
      }
      this.$refs.myVideo.srcObject = undefined;
      this.$refs.friendVideo.srcObject = undefined;
    }
  },
  mounted() {
    const then = this;  //保存Vue组件实例的引用
		//监听localStorage事件
		window.addEventListener('setItemEvent', (e)=> {
			// 是需要监听的键名
			if (e.key === 'sum') {   
				// 这里的newValue就是localStorage中，键名为tableData对应的变化后的值。
				then.sum=e.newValue
			}
		})
  },
  created(){
    var click= localStorage.getItem('click')
    if(click){
      if(click=="t1"){
        this.t1 = true
      }else if(click=="t2"){
        this.t2 = true
      }else{
        this.t3=true
      }
    }else{
      this.t1=true
    }
    //获得保存在本地的登录用户数据
    this.user = JSON.parse(localStorage.getItem('user'));
    //获得头像信息的方法
    this.getAvatar();
    //文件上传地址
    this.uploadPath = this.$url.http + "/minio/upload"
    //朋友圈背景图片
    this.getImg();
    //初始化peer服务
    this.getConnect()
  }
};
</script>

<style scoped>
.left {
  height: 100%;
  width: 7%;
  float: left;
  background-color: black;
}

.right {
  height: 100%;
  width: 92%;
  float: right;
}

a {
  text-decoration: none;
  color: aliceblue;
}

/deep/ .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: red;
}

/deep/ .el-badge__content.is-fixed {
  margin-top: 9px;
  margin-right: 5px;
}
</style>