<template>
    <div style="width:100%;height:100%">
        <div class="cls">
            <div style="width:30%;height:35%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                <img :src="friend.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
            </div>
            <div style="float: left;width:60%;height:35%;margin-top:7px;margin-left:10px;float:left;overflow: hidden;">
                <span style="font-size: 18px;"><b>{{ friend.userName }}</b></span><br />
                <div style="height:10px"></div>
                <span style="font-size: 14px;">备注：
                    <input type="text" v-model="friend.firendName" @blur="updateName" autofocus="autofocus"
                        style="width:80px" />
                </span><br />
                <span style="font-size: 14px;">账号：{{ friend.firendId }}</span>
            </div>
        </div>
    </div>
</template> 

<script>
export default {
    name: 'Detail',
    beforeRouteUpdate(to, from, next) {
        //next方法使URL显示正常
        next()
        //数据改变的方法（此处为自定义的数据改变方法）
        this.getTo(to.query.friendId)
    },
    data() {
        return {
            user: {
            },
            friend: {
            }
        }
    },
    methods: {
        //自定义的数据改变方法
        getTo(id) {
            //获取路由传值
            this.user.firendId = id;
            //获得保存在本地的登录用户数据
            this.user.userId = JSON.parse(localStorage.getItem('user')).userId;
            //进入页面渲数据
            this.getFriend();
        },
        //根据好友编号和我的id获得好友详细信息
        getFriend() {
            this.$axios.post('user/getUserAndFriend', this.user).then(resp => {
                this.friend = resp.data.data;
            });
        },
        //修改备注
        updateName() {
            if (this.friend.firendName.length > 6) {
                this.$message({
                    showClose: false,
                    message: "备注最多6位数！",
                    type: 'error',
                });
                return;
            }
            var friend = {
                "friendId":this.user.userId,
                "isFriendId":this.user.firendId,
                "isFriendName": this.friend.firendName
            }
            //修改用户信息成功之后，修改浏览器保存的用户信息
            this.$axios.post('friendApply/updateName', friend).then(resp => {
                //修改成功刷新数据
               this.getFriend();
               //向父路由传值
               this.$emit('func',resp.data)
            });
        },
    },
    mounted() {
        this.getTo(this.$route.query.friendId);
    }
}
</script>
<style scoped>
.cls {
    width: 400px;
    height: 300px;
    position: absolute;
    left: 65%;
    top: 40%;
    -webkit-transform: translateX(-50%) translateY(-50%);
}
</style>