import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    token: "",
    emoji:[]
  },
  getters: {
    show(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      //将token放入本地存储
      localStorage.setItem("token", token)
    },
    //保存emoji编码数据
    setEmoji(state,value){
      state.emoji=value
    }
  },
  actions: {
  },
})




