<template>
    <div style="width:70%;margin: auto;margin-top:20px;">
            <div style="width:70px;height:80px;float: left;margin-left:10px;margin-top:10px" v-for="m in member">
                <div style="width:70px;height:60px;float:left;overflow: hidden;">
                    <img :src="m.groupUrl" width="100%" height="100%" style="object-fit: cover;" />
                </div>
                <span style="font-size: 13px;">{{ m.nickName | jiequ }}</span>
            </div>
        </div>
</template> 

<script>
export default {
    name: 'GroupDetail',
    beforeRouteUpdate(to, from, next) {
        //next方法使URL显示正常
        next()
        //数据改变的方法（此处为自定义的数据改变方法）
        this.getTo(to.query.groupId)
    },
    data() {
        return {
            groupId: '',
            member: []
        }
    },
    filters: {
        //内容长度过长显示省略号
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 10) {
                return value.slice(0, 10) + '...'
            }
            return value
        },
        //选中栏名字长度过长显示省略号
        jiequ(value) {
            if (!value) return '';
            if (value.length > 5) {
                return value.slice(0, 5) + '...'
            }
            return value
        }
    },
    methods: {
        //自定义的数据改变方法
        getTo(id) {
            //获取路由传值
            this.groupId = id;
            //进入页面渲数据
            this.geList();
        },
        //根据好友编号和我的id获得好友详细信息
        geList() {
            this.$axios.post('group/getMemberList', { 'groupId': this.groupId }).then(resp => {
                this.member = resp.data.data;
            });
        }
    },
    mounted() {
        this.getTo(this.$route.query.groupId);
    }
}
</script>
<style scoped></style>