<template>
    <div style="width:100%;height:100%">
        <!--上层-->
        <div style="width: 30%;height:6%;background-color:rgba(255, 255, 255, 0.247);border-right: 0.5px black solid; margin-left: -9px;
        border-bottom: 0.5px black solid;">
            <div style="float: right;margin-right:5px">
                <span style="font-size:25px">
                    <i class="el-icon-circle-plus-outline" @click="getTrue" v-if="dis == true"></i>
                    <i class="el-icon-circle-plus-outline" v-if="dis == false"></i>
                </span>
            </div>
        </div>
        <!--聊天框-->
        <div class="left">
            <div style="width: 100%;height:60px;" v-for="(ms, index) in messages"
                @click="toMessage(ms.id, ms.name, index, ms.fileUrl, ms.type)"
                :style="ms.active ===true ? { backgroundColor: 'rgba(213, 210, 210)' } : { backgroundColor: 'rgba(255, 255, 255, 0.247)' }">
                <a href="#">
                    <div style="width:20%;height:80%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                        <img :src="ms.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                    </div>
                    <div style="width:20px;height:20px;border-radius: 10px;background-color:red;float:left;margin-top:1px;
                    margin-left:-7px" v-if="ms.num > 0">
                        <span style="color:white;margin-left:5px" v-if="ms.num <= 9">{{ ms.num }}</span>
                        <span style="color:white;" v-if="ms.num >= 10 && ms.num <= 99">{{ ms.num }}</span>
                        <div style="height:15px;color:white;margin-left:3px;margin-top:-9px;font-size:20px"
                            v-if="ms.num >= 100">
                            <span>...</span>
                        </div>
                    </div>
                    <div style="width:20px;height:20px;float:left;margin-top:1px;
                    margin-left:-7px" v-if="ms.num == 0">
                    </div>
                    <div style="width:71%;height:80%;margin-left:10px;float:right;margin-top:-15px;color:black">
                        <div style="width:98%;height:30px;">
                            <div style="width:60%;height:100%;float:left;font-weight:bold;">
                                <div style="margin-top:3px;margin-left:5px">
                                    {{ ms.name | jiequ }}
                                </div>
                            </div>
                            <div style="width:40%;height:100%;float:left;font-size:10px">
                                {{ ms.dateTime }}
                            </div>
                        </div>
                        <span style="font-size:13px;float:left;margin-left:5px;" v-if="ms.messageType == '0'">
                            <span v-html="$options.filters.ellipsis(ms.context)"></span>
                        </span>
                        <span style="font-size:13px;float:left;margin-left:5px;" v-if="ms.messageType == '1'">图片</span>
                        <span style="font-size:13px;float:left;margin-left:5px;" v-if="ms.messageType == '2'">视频</span>
                        <span style="font-size:13px;float:left;margin-left:5px;"
                            v-if="ms.messageType != '2' && ms.messageType != '1' && ms.messageType != '0'">文档</span>
                    </div>
                </a>
            </div>
            <!-- <div style="height:70px;">
                <router-link to="e">去demo</router-link>
            </div> -->
            <!-- <div style="height:70px;">
                <router-link to="show">图片上传</router-link>
            </div> -->
        </div>
        <!--拉群-->
        <div class="main" v-show="status">
            <!--左边-->
            <div style="width:300px;height:100%;border-right:1px #DCDFE6 solid;float: left;">
                <!--搜索框-->
                <div style="width:98%;height:10%;">
                    <!-- <el-input placeholder="名称" v-model="sou" @oninput="getTrue">
                        <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input> -->
                    <input type="text" class="sou" placeholder="好友备注..." v-model="sou" @input="getTrue" />
                </div>
                <!--好友-->
                <div style="width:98%;height:89%">
                    <div style="width:100%" v-for="item in friendList">
                        <!--字母编码-->
                        <div style="width:100%;height:25px;background-color:white;">
                            <span style="margin-top: 3px;margin-left:5px;">{{ item.code }}</span>
                        </div>
                        <!--好友数据-->
                        <div style="width:100%;height:50px;" v-for="friend in item.users">
                            <el-checkbox @change="add(friend.userId, friend.firendName, $event, friend.fileUrl)"
                                class="myRedCheckBox" border style="width:100%;height:100%">
                                <div style="width:50px;height:40px;float:left;overflow: hidden;margin-top:-5px">
                                    <img :src="friend.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                                </div>
                                <span style="margin-left:10px">{{ friend.firendName }}</span>
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!--右边-->
            <div style="width:290px;height:100%;float:left;margin-left:7px">
                <!--群聊名称-->
                <div style="width:100%;height:10%;">
                    <el-input placeholder="群聊名称" v-model="value" clearable maxlength="12" show-word-limit>
                    </el-input>
                </div>
                <!--选择成员-->
                <div style="width:100%;height:79%;">
                    <div style="width:100%;height:20px;">
                        <span style="float:right;color:silver;font-size: 13px;">
                            已选择{{ checkedList.length }}个联系人
                        </span>
                    </div>
                    <div class="checks">
                        <div style="width:70px;height:70px;float: left;margin-left:18px;margin-top:10px"
                            v-for="check  in checkedList">
                            <div style="width:60px;height:50px;float:left;overflow: hidden;margin-left:5px">
                                <img :src="check.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                            </div>
                            <span style="margin-left:15px;font-size: 13px;">{{ check.nickName | jiequs }}</span>
                        </div>
                    </div>
                </div>
                <div style="width:100%;height:10%;">
                    <div style="margin-left:40px;margin-top:7px">
                        <el-button size="medium" type="primary" @click="addGroup">确定</el-button>
                        <el-button size="medium" style="margin-left:20px" @click="getFalse">取消</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!--聊天内容-->
        <div class="right">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Message',
    data() {
        return {
            user: {
                userId: "",
                userName: ""
            },
            messages: [],
            status: false,//div层显示隐藏
            //好友列表
            friendList: [],
            checkedList: [],//选中值
            value: '',//群聊名称
            sou: '',//搜索框
            dis: true,//创建群聊按钮是否可以点击
            mes: {},//存储被选中的聊天框
            token:''
        }
    },
    methods: {
        //根据聊天框类型，跳转至与好友聊天页面或者群聊页面
        toMessage(id, name, index, url, type) {
            /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 点击聊天框改变背景颜色*/
            for (let i in this.messages) {
                if (index == i) {
                    this.messages[i].active = !this.messages[i].active
                    //将被点击的聊天框存储，当聊天框列表刷新时判断是否有被选中
                    this.mes = this.messages[i];
                } else {
                    this.messages[i].active = false;
                }
            }
            //当聊天框类型为friend时则去往好友聊天页面，group则去往群聊页面
            if (type == "friend") {
                //将未读消息修改为已读
                var m = {
                    messageId: this.user.userId,
                    isMessageId: id,
                    news: 'true'
                }
                this.getNum(JSON.stringify(m));
                this.$router.push({ name: "Chat", query: { id: id, name: name, url: url } });
            } else {
                //发送消息，将我收到该群聊最后一条消息的时间为当前时间实现未读消息全已读功能
                var m = {
                    messageId: this.user.userId,
                    isMessageId: id,
                    news: 'click'
                }
                this.getNum(JSON.stringify(m));
                this.$router.push({ name: "Group", query: { id: id, name: name } });
            }
            //进入聊天页面时将创建群聊按钮设置不可点击
            this.dis = false;
        },
        //获得聊天框
        getList() {
            var headers={
                userId:this.user.userId,
                accessToken:this.token
            }
            var url = this.$url.path + "/messageWebSocket/" + this.user.userId;
            var websock = new WebSocket(url);
            //设置全局websocket
            this.$ws.setWs(websock)
            //获得ws消息
            this.$ws.ws.onmessage = this.websocketonmessage
        },
        websocketonmessage(e) {
            var mes = JSON.parse(e.data);
            this.messages = mes;
            //判断是否有被选中框，改变其背景颜色状态值
            if (this.mes.id) {
                for (let i in this.messages) {
                    if (this.messages[i].id===this.mes.id) {
                        this.messages[i].active = !this.messages[i].active
                    }
                }
            }
            //将数据总条数保存在浏览器
            var num = 0;
            mes.forEach((item) => { num += item.num })
            localStorage.setItem("sum", num)
        },
        //div层显示
        getTrue() {
            var data = { 'firendName': this.sou };
            this.$axios.post('user/allFriend', data).then(resp => {
                this.friendList = resp.data.data;
            });
            this.status = true;
        },
        //div层隐藏
        getFalse() {
            this.status = false;
            this.friendList = [];
            this.checkedList = [];
            this.sou = ""
        },
        //复选框选中和取消选中事件
        add(id, name, data, fileUrl) {
            var member = {
                "memberId": id,
                "nickName": name,
                "fileUrl": fileUrl
            }
            //选中复选框添加值至集合显示
            if (data == true) {
                this.checkedList.push(member);
            } else {
                //取消选中，从集合中删除该数据
                let index = this.checkedList.findIndex(item => {
                    if (item.memberId == id) {
                        return true;
                    }
                });
                this.checkedList.splice(index, 1)
            }
        },
        //创建群聊
        addGroup() {
            if (this.value == null || this.value == undefined || this.value == '') {
                this.$message({
                    showClose: true,
                    message: '请填写群聊名称',
                    type: 'error',
                });
                return;
            }
            //群聊数据
            var group = {
                'groupName': this.value,
                'createGroupId': this.user.userId,
                'createGroupName': this.user.userName,
                'list': this.checkedList
            }
            //创建群聊
            this.$axios.post('group/addGroup', group).then(resp => {
                //向websocket发送消息刷新聊天框数据
                if (resp.data.code == "200") {
                    //关闭群聊添加框
                    this.status = false;
                    this.friendList = [];
                    this.checkedList = [];
                    this.sou = ""
                    //发送消息
                    var m = {
                        messageId: this.user.userId,
                        isMessageId: resp.data.data.groupId,
                        news: 'group'
                    }
                    this.getNum(JSON.stringify(m));
                }
            })
        },
        //重新查询未读消息数量
        getNum(e) {
            //向聊天框websocket发送一条重新查询聊天框未读数量
            this.$ws.ws.send(e);
        }
    },
    filters: {
        //内容长度过长显示省略号
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 10) {
                return value.slice(0, 10) + '...'
            }
            return value
        },
        //选中栏名字长度过长显示省略号
        jiequ(value) {
            if (!value) return '';
            if (value.length > 5) {
                return value.slice(0, 5) + '...'
            }
            return value
        },
        jiequs(value) {
            if (!value) return '';
            if (value.length > 4) {
                return value.slice(0, 4) + '...'
            }
            return value
        }
    },
    mounted() {
        //获得保存在本地的登录用户数据
        this.token= localStorage.getItem('token')
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getList();
    },
    //离开页面关闭websocket连接
    // beforeRouteLeave(to, from, next) {
    //     this.$ws.ws.onclose = () => {
    //         next();
    //     };
    //     this.$ws.ws.close();
    // }
}
</script>

<style scoped>
.left {
    width: 30%;
    height: 94%;
    border-right: 0.5px black solid;
    margin-left: -9px;
    float: left;
    overflow-y: auto;

}

.right {
    width: 70%;
    height: 100%;
    float: right;
    margin-right: 6px;
    margin-top: -34px;
}


/* 整个滚动条 */
.left::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
}

/* 滚动条里面滑块 */
.left::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.left::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}


.main {
    width: 600px;
    height: 450px;
    margin-left: 50px;
    margin-top: -20px;
    background-color: white;
    position: absolute;
    box-shadow: 18px 18px 30px rgba(0, 0, 0, 0.1),
        -18px -18px 30px rgba(0, 0, 0, 0.1);
}

.sou {
    width: 260px;
    height: 80%;
    border: 1px #DCDFE6 solid;
    border-radius: 5px;
    padding-left: 30px;
    background: url(../imgs/sou.png) no-repeat 5px 8px;
    /**设置图片背景透明度 */
    opacity: 0.4;
}

.checks {
    width: 100%;
    height: 94%;
    overflow-y: auto;
}

/* 整个滚动条 */
.checks::-webkit-scrollbar {
    width: 8px;
}

/* 滚动条里面滑块 */
.checks::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: skyblue;
}

/*滚动条里面轨道*/
.checks::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background: #eee;
}

/deep/ .el-input .el-input__count .el-input__count-inner {
    background-color: rgba(255, 255, 255, 0.247);
}
</style>