<template>
  <div id="m">
    <div id="cla">
      <router-view />
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  //适配
  mounted() {
    autofit.init(
      {
        dh: 720,
        dw: 1700,
        el: "#m",
        resize: true
      },
      false
    );
  }
};

//每次路由跳转刷新页面
//<router-view v-if="isRouterAlive"/>
// export default {
//   name: 'App',
//   provide() {
//     return {
//       reload: this.reload
//     }
//   },
//   data() {
//     return {
//       isRouterAlive: true
//     }
//   },
//   methods: {
//     reload() {
//       this.isRouterAlive = false
//       this.$nextTick(function () {
//         this.isRouterAlive = true
//       })
//     },
//   },
// }
</script>

<style scoped>
* {
  /* 初始化 取消页面元素内外边距 */
  margin: 0;
  padding: 0;
}

#m {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(@/imgs/main.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#cla {
  width: 900px;
  height: 550px;
  box-shadow: 18px 18px 30px rgba(0, 0, 0, 0.2),
    -18px -18px 30px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
}
</style>


