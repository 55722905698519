<template>
    <div style="width:100%;height:100%">
        <div style="width: 100%;height:70px;float:left;border-bottom:0.2px black solid">
            <div style="font-size:20px;margin-top:10px">
                账号：<input id="text" type="text" v-model="user.userId" @input="getList" style="width: 50%;height:30px" />
            </div>
        </div>
        <div class="cls" style="width:100%;height:85%;float:left;margin-top:10px;overflow-y: auto;">
            <div v-for="u in users" style="width:100%;height:80px;margin-top:5px;background-color:rgba(255, 255, 255, 0.247);">
                <div style="width:18%;height:95%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                    <img :src="u.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                </div>
                <div style="width:71%;height:80%;margin-right:40px;float:right;margin-top:16px;font-size: 14px;">
                    <span>昵称：{{ u.userName }}</span><br />
                    <div style="height:10px"></div>
                    <span>账号：{{ u.userId }}</span>
                </div>
                <div style="float:right;margin-top:-55px;font-size: 14px;margin-right:10px">
                    <span v-if="u.isNot == 'true'">他已成为您的朋友</span>
                    <span v-if="u.isNot == 'false'">
                        <span v-if="u.userId == my.userId"></span>
                        <el-button size="small" type="success" v-else @click="friendMessage(u.userId)">好友申请</el-button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
export default {
    name: 'Search',
    data() {
        return {
            user: {
                userId: ''
            },
            users: [
            ],
            my: {}
        }
    },
    methods: {
        //模糊匹配
        getList() {
            this.$axios.post('user/selectNewMoHu', this.user).then(resp => {
                this.users = resp.data.data;
            });
        },
        //好友申请
        friendMessage(id) {
            var apply = {
                friendApplyId: this.my.userId,
                isFriendApplyId: id,
                status: '0',
                decide: '0'
            }
            this.$axios.post('friendApply/applyMessage', apply).then(resp => {
                this.$message({
                    showClose: true,
                    message: "申请成功！",
                    type: 'success',
                });
                //刷新数据
                this.getList();
            });
        }
    },
    mounted() {
        //获得保存在本地的登录用户数据
        this.my = JSON.parse(localStorage.getItem('user'));
        this.getList();
    }
}
</script>
<style scoped>
/* 整个滚动条 */
.cls::-webkit-scrollbar {
    width: 8px;
}

/* 滚动条里面滑块 */
.cls::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: skyblue;
}

/*滚动条里面轨道*/
.cls::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background: #eee;
}
</style>