import { render, staticRenderFns } from "./FriendPen.vue?vue&type=template&id=34d8ded5&scoped=true&"
import script from "./FriendPen.vue?vue&type=script&lang=js&"
export * from "./FriendPen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d8ded5",
  null
  
)

export default component.exports