//引入路由组件
import Login from '@/views/login/Login.vue'
import Enroll from '@/views/login/Enroll.vue'
import Register from '@/views/login/Register.vue'
import Main from '@/views/Main.vue'
import Message from '@/views/Message.vue'
import Friend from '@/views/Friend.vue'
import Chat from '@/views/Chat.vue'
import Detail from '@/views/Detail.vue'
import New from '@/views/New.vue'
import Search from '@/views/Search.vue'
import Test from '@/views/Test.vue'
import Emoji from '@/views/Emoji.vue'
import Show from '@/views/Show.vue'
import Group from '@/views/Group.vue'
import GroupDetail from '@/views/GroupDetail.vue'
import FriendPen from '@/views/FriendPen.vue'
import Pen from '@/views/Pen.vue'
import ImgPen from '@/views/ImgPen.vue'

//配置路由
var routes = [
    {
        path: "/", component: Main, meta: {
            title: '首页'
        }, children: [
            {
                path: "/", name: "Message", component: Message, meta: {
                    title: '消息'
                }, children: [
                    {
                        path: "/chat/:id?/:name?/:url?", name: "Chat", component: Chat, meta: {
                            title: '聊天'
                        },
                    },
                    {
                        path: "/group/:id?/:name?", name: "Group", component: Group, meta: {
                            title: '群聊'
                        },
                    },
                    {
                        path: "/t", name: "test", component: Test, meta: {
                            title: '测试'
                        }
                    },
                    {
                        path: "/e", name: "e", component: Emoji, meta: {
                            title: '表情'
                        }
                    },
                    {
                        path: "/show", name: "show", component: Show, meta: {
                            title: '图片上传'
                        }
                    },
                ]
            },
            {
                path: "/friend", name: "Friend", component: Friend, meta: {
                    title: '好友'
                }, children: [
                    {
                        path: "/detail/:friendId?", name: "Detail", component: Detail, meta: {
                            title: '详细信息'
                        },
                    },
                    {
                        path: "/groupdetail/:groupId?", name: "GroupDetail", component: GroupDetail, meta: {
                            title: '群聊信息'
                        },
                    },
                    {
                        path: "/new", name: "New", component: New, meta: {
                            title: '新的朋友'
                        },
                    },
                    {
                        path: "/search", name: "Search", component: Search, meta: {
                            title: '查找账号'
                        },
                    },
                ]
            },
            {
                path: "/friendPen", name: "FriendPen", component: FriendPen, meta: {
                    title: '盆友圈'
                }, children: [
                    {
                        path: "/", name: "Pen", component: Pen, meta: {
                            title: '盆友圈'
                        }, 
                    },
                    {
                        path: "/imgPen", name: "imgPen", component: ImgPen, meta: {
                            title: ''
                        }, 
                    }
                ]
            },
        ]
    }, {
        path: "/login", name: "Login", component: Login, meta: {
            title: '登录'
        }, children: [
            {
                path: "/register", name: "Register", component: Register, meta: {
                    title: '注册'
                },
            },
            {
                path: "/", name: "Enroll", component: Enroll, meta: {
                    title: '登录'
                },
            }
        ]
    },
]

//导出
export default routes;