<template>
    <div class="far_box">
        <div>显示内容：{{ myText }}</div>
        <VueEmoji ref="emoji" :value="myText" @input="onInput" />
        <button @click="clearTextarea">clear</button>
    </div>
</template>
  
<script>
import VueEmoji from '@/emoji/VueEmoji.vue'
export default {
    components: {
        VueEmoji
    },
    data() {
        return {
            myText: '微信表情'
        }
    },
    methods: {
        onInput(event) {
            this.myText = event.data
            // event.data contains the value of the textarea
        },
        clearTextarea() {
            this.$refs.emoji.clear()
            this.myText = ''
        }
    }
}
</script>
  
<style  scope>
/*扩大盒子高度--这样在点击表情外的地方时候 触发失焦 关闭表情盒子*/
.far_box {
    height: 100vh;
    text-align: left;
}

element.style {
    width: 85%;
    height: 50px;
}
</style>
  
  