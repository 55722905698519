import axios from "axios";
import Element from "element-ui";
import router from '@/router/index.js'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.withCredentials = true;  //允许跨域
const request = axios.create({
    timeout: 10000,
    headers: {
        'Content-Type': "application/json;charset=utf-8"
    }
})

//请求拦截器
request.interceptors.request.use(config => {
    //拿到本地存储的token
    const tokenStr = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    if (tokenStr) {
        //将token放入请求头中，后端拦截器优先判断token
        config.headers.accessToken = tokenStr
    }
    if (user) {
        config.headers.userId = user.userId
    }
    return config;
})


//响应拦截器
request.interceptors.response.use(response => {
    let res = response.data
    if (res.code === 200) {
        return response
    } else if (res.code === 400) {
        Element.Message.error(!res.message ? '系统异常' : res.message)
        return Promise.reject(response.data.message)
    } else if (res.code === 403) {
        Element.Message.error(!res.message ? '系统异常' : res.message)
        return Promise.reject(response.data.message)
    } else if (res.code === 500) {
        Element.Message.error(!res.message ? '系统异常' : res.message)
        return Promise.reject(response.data.message)
    } else if (res.code === 405) {
        Element.Message.error("请求方式错误!")
        return Promise.reject(response.data.message)
    } else if (res.code === 401) {//token过期
        // 显示提示框
        // Element.MessageBox.confirm(res.message, '提示', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     showClose:false
        // }).then(() => {
        //     // 点击确定按钮，跳转到登录页面
        //     router.push('/login')
        // }).catch(()=>{
        //     router.push('/login')
        // })
        Element.Message.error(res.message)
        //清除本地所有缓存
        localStorage.clear();
        router.push('/login')
        return Promise.reject(response.data.message)
    }
    return res;
}
)


export default request;



