<template>
    <div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" size="large">
            <el-form-item prop="userId">
                账号：<el-input v-model="loginForm.userId" placeholder="请输入账号" style="width:80%;" />
            </el-form-item>
            <el-form-item prop="qqCode">
                <el-input v-model="loginForm.qqCode" placeholder="验证码" clearable style="width:46%;margin-left: 11.5%;"
                    maxlength="6" />
                <el-button style="margin-left: 10px;background-color:rgba(255, 255, 255, 0.247);" @click="sendMeail"
                    v-if="status == true">发送验证码</el-button>
                <el-button style="width:30%;margin-left: 10px;background-color:rgba(255, 255, 255, 0.247);color:#66b1ff"
                    v-if="status == false" :disabled="true">{{ t_Seconds }}</el-button>
            </el-form-item>
            <el-form-item prop="userName">
                昵称：<el-input v-model="loginForm.userName" placeholder="请输入昵称" style="width:80%;" maxlength="6"
                    show-word-limit />
            </el-form-item>
            <el-form-item prop="userPass">
                密码：<el-input v-model="loginForm.userPass" type="password" placeholder="请输入密码" style="width:80%;" />
            </el-form-item>
            <el-form-item style="margin-top:8%">
                <el-button type="primary" style="width:100%;background-color:#66b1ff" @click="submitForm">
                    <span style="font-size: 18px;">注 册</span>
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                //密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
                var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?+-\/]).*$/;
                if (!pPattern.test(value)) {
                    callback(new Error('最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符'));
                } else {
                    callback();
                }
            }
        }
        var validateUsername = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入账号'));
            } else {
                var uPattern = /^.*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!uPattern.test(value)) {
                    callback(new Error('qq邮箱账号'));
                } else {
                    callback();
                }
            }
        }
        return {
            loginForm: {
                userId: '',
                userPass: '',
                qqCode: '',
                userName: ''
            },
            loginRules: {
                userId: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { validator: validateUsername, trigger: 'blur' },
                ],
                userName: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                ],
                userPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'change' },
                ],
                qqCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
            },
            status: true,
            time: 60
        }
    },
    methods: {
        sendMeail() {
            if (this.loginForm.userId == '') {
                this.$message({
                    showClose: true,
                    message: "请输入账号！",
                    type: 'error',
                });
                return;
            }
            this.$axios.post('user/sendEmail/' + this.loginForm.userId).then(resp => {
                this.$message({
                    showClose: true,
                    message: '发送成功',
                    type: 'success',
                });
                setInterval(this.updateTime, 1);
                this.status = false;
            });
        },
        submitForm() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    //注册
                    this.$axios.post("user/register", this.loginForm).then(resp => {
                        this.$message({
                            showClose: true,
                            message: resp.data.data,
                            type: 'success',
                        });
                        this.$router.push({ name: 'Enroll' });
                    });
                }
            });
        },
        updateTime() {
            if (this.time > 0) {
                this.time -= 0.004;
            } else {
                this.status = true
                this.time=60
            }
        },
    },
    mounted() {

    },
    computed: {
        t_Minutes() {
            const minutes = Math.floor(this.time / 60);
            return minutes.toString().padStart(2, '0');
        },
        t_Seconds() {
            const seconds = Math.floor(this.time % 60);
            return seconds.toString().padStart(2, '0');
        },
        t_Milliseconds() {
            const milliseconds = Math.floor((this.time % 1) * 100);
            return milliseconds.toString().padStart(2, '0');
        },
    },
    // created() {
    //     document.title = '注册';
    // }
}
</script>

<style scoped>
/deep/ .el-input__inner {
    background-color: rgba(255, 255, 255, 0.247);
}

/deep/ .el-input .el-input__count .el-input__count-inner {
    background-color: rgba(255, 255, 255, 0.247);
}
</style>