export default {
    iconList:[
		{
			"id": 1,
			"code": "&#128512;",
			"emoji": "😀"
		},
		{
			"id": 2,
			"code": "&#128513;",
			"emoji": "😁"
		},
		{
			"id": 3,
			"code": "&#128514;",
			"emoji": "😂"
		},
		{
			"id": 4,
			"code": "&#128515;",
			"emoji": "😃"
		},
		{
			"id": 5,
			"code": "&#128516;",
			"emoji": "😄"
		},
		{
			"id": 6,
			"code": "&#128517;",
			"emoji": "😅"
		},
		{
			"id": 7,
			"code": "&#128518;",
			"emoji": "😆"
		},
		{
			"id": 8,
			"code": "&#128519;",
			"emoji": "😇"
		},
		{
			"id": 9,
			"code": "&#128520;",
			"emoji": "😈"
		},
		{
			"id": 10,
			"code": "&#128521;",
			"emoji": "😉"
		},
		{
			"id": 11,
			"code": "&#128522;",
			"emoji": "😊"
		},
		{
			"id": 12,
			"code": "&#128523;",
			"emoji": "😋"
		},
		{
			"id": 13,
			"code": "&#128524;",
			"emoji": "😌"
		},
		{
			"id": 14,
			"code": "&#128525;",
			"emoji": "😍"
		},
		{
			"id": 15,
			"code": "&#128526;",
			"emoji": "😎"
		},
		{
			"id": 16,
			"code": "&#128527;",
			"emoji": "😏"
		},
		{
			"id": 17,
			"code": "&#128528;",
			"emoji": "😐"
		},
		{
			"id": 18,
			"code": "&#128529;",
			"emoji": "😑"
		},
		{
			"id": 19,
			"code": "&#128530;",
			"emoji": "😒"
		},
		{
			"id": 20,
			"code": "&#128531;",
			"emoji": "😓	"
		},
		{
			"id": 21,
			"code": "&#128532;",
			"emoji": "😔"
		},
		{
			"id": 22,
			"code": "&#128533;",
			"emoji": "😕"
		},
		{
			"id": 23,
			"code": "&#128534;",
			"emoji": "😖"
		},
		{
			"id": 24,
			"code": "&#128535;",
			"emoji": "😗"
		},
		{
			"id": 25,
			"code": "&#128536;",
			"emoji": "😘"
		},
		{
			"id": 26,
			"code": "&#128537;",
			"emoji": "😙"
		},
		{
			"id": 27,
			"code": "&#128538;",
			"emoji": "😚"
		},
		{
			"id": 28,
			"code": "&#128539;",
			"emoji": "😛"
		},
		{
			"id": 29,
			"code": "&#128540;",
			"emoji": "😜"
		},
		{
			"id": 30,
			"code": "&#128541;",
			"emoji": "😝"
		},
		{
			"id": 31,
			"code": "&#128542;",
			"emoji": "😞"
		},
		{
			"id": 32,
			"code": "&#128543;",
			"emoji": "😟"
		},
		{
			"id": 33,
			"code": "&#128544;",
			"emoji": "😠"
		},
		{
			"id": 34,
			"code": "&#128545;",
			"emoji": "😡"
		},
		{
			"id": 35,
			"code": "&#128546;",
			"emoji": "😢"
		},
		{
			"id": 36,
			"code": "&#128547;",
			"emoji": "😣"
		},
		{
			"id": 37,
			"code": "&#128548;",
			"emoji": "😤"
		},
		{
			"id": 38,
			"code": "&#128549;",
			"emoji": "😥"
		},
		{
			"id": 39,
			"code": "&#128550;",
			"emoji": "😦"
		},
		{
			"id": 40,
			"code": "&#128551;",
			"emoji": "😧"
		},
		{
			"id": 41,
			"code": "&#128552;",
			"emoji": "😨"
		},
		{
			"id": 42,
			"code": "&#128553;",
			"emoji": "😩"
		},
		{
			"id": 43,
			"code": "&#128554;",
			"emoji": "😪"
		},
		{
			"id": 44,
			"code": "&#128555;",
			"emoji": "😫"
		},
		{
			"id": 45,
			"code": "&#128556;",
			"emoji": "😬"
		},
		{
			"id": 46,
			"code": "&#128557;",
			"emoji": "😭"
		},
		{
			"id": 47,
			"code": "&#128558;",
			"emoji": "😮"
		},
		{
			"id": 48,
			"code": "&#128559;",
			"emoji": "😯"
		},
		{
			"id": 49,
			"code": "&#128560;",
			"emoji": "😰"
		},
		{
			"id": 50,
			"code": "&#128561;",
			"emoji": "😱"
		},
		{
			"id": 51,
			"code": "&#128562;",
			"emoji": "😲"
		},
		{
			"id": 52,
			"code": "&#128563;",
			"emoji": "😳"
		},
		{
			"id": 53,
			"code": "&#128564;",
			"emoji": "😴"
		},
		{
			"id": 54,
			"code": "&#128565;",
			"emoji": "😵"
		},
		{
			"id": 55,
			"code": "&#128566;",
			"emoji": "😶"
		},
		{
			"id": 56,
			"code": "&#128567;",
			"emoji": "😷"
		},
		{
			"id": 57,
			"code": "&#128568;",
			"emoji": "😸"
		},
		{
			"id": 58,
			"code": "&#128569;",
			"emoji": "😹"
		},
		{
			"id": 59,
			"code": "&#128570;",
			"emoji": "😺"
		},
		{
			"id": 60,
			"code": "&#128571;",
			"emoji": "😻"
		},
		{
			"id": 61,
			"code": "&#128572;",
			"emoji": "😼"
		},
		{
			"id": 62,
			"code": "&#128573;",
			"emoji": "😽"
		},
		{
			"id": 63,
			"code": "&#128574;",
			"emoji": "😾"
		},
		{
			"id": 64,
			"code": "&#128575;",
			"emoji": "😿"
		},
		{
			"id": 65,
			"code": "&#128576;",
			"emoji": "🙀"
		},
		{
			"id": 66,
			"code": "&#128577;",
			"emoji": "🙁"
		},
		{
			"id": 67,
			"code": "&#128578;",
			"emoji": "🙂"
		},
		{
			"id": 68,
			"code": "&#128579;",
			"emoji": "🙃"
		},
		{
			"id": 69,
			"code": "&#128580;",
			"emoji": "🙄"
		},
		{
			"id": 70,
			"code": "&#129296;",
			"emoji": "🤐"
		},
		{
			"id": 71,
			"code": "&#129297;",
			"emoji": "🤑"
		},
		{
			"id": 72,
			"code": "&#129298;",
			"emoji": "🤒"
		},
		{
			"id": 73,
			"code": "&#129299;",
			"emoji": "🤓"
		},
		{
			"id": 74,
			"code": "&#129300;",
			"emoji": "🤔"
		},
		{
			"id": 75,
			"code": "&#129301;",
			"emoji": "🤕"
		},
		{
			"id": 76,
			"code": "&#129312;",
			"emoji": "🤠"
		},
		{
			"id": 77,
			"code": "&#129313;",
			"emoji": "🤡"
		},
		{
			"id": 78,
			"code": "&#129314;",
			"emoji": "🤢"
		},
		{
			"id": 79,
			"code": "&#129315;",
			"emoji": "🤣"
		},
		{
			"id": 80,
			"code": "&#129316;",
			"emoji": "🤤"
		},
		{
			"id": 81,
			"code": "&#129317;",
			"emoji": "🤥"
		},
		{
			"id": 83,
			"code": "&#129319;",
			"emoji": "🤧"
		},
		{
			"id": 84,
			"code": "&#129320;",
			"emoji": "🤨"
		},
		{
			"id": 85,
			"code": "&#129321;",
			"emoji": "🤩"
		},
		{
			"id": 86,
			"code": "&#129322;",
			"emoji": "🤪"
		},
		{
			"id": 87,
			"code": "&#129323;",
			"emoji": "🤫"
		},
		{
			"id": 88,
			"code": "&#129324;",
			"emoji": "🤬"
		},
		{
			"id": 89,
			"code": "&#129325;",
			"emoji": "🤭"
		},
		{
			"id": 90,
			"code": "&#129326;",
			"emoji": "🤮"
		},
		{
			"id": 91,
			"code": "&#129327;",
			"emoji": "🤯"
		},
		{
			"id": 92,
			"code": "&#129488;",
			"emoji": "🧐"
		}
	]
}