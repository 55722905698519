<template>
    <div style="width:100%;height:100%">
        <div class="left">
            <div style="width: 100%;height:60px;background-color:whitesmoke;margin-top:10px">
                <a href="#" style="width: 100%;height:70%;" @click="toSearch">
                    <div style="width: 100%;height:70%;background-color:khaki;color:black">
                        <i class="el-icon-thumb" style="font-size:35px"></i>
                        <span style="font-size: 18px;margin-left:5px;position: absolute;margin-top:7px">查找账号</span>
                    </div>
                </a>
            </div>
            <div
                style="width: 100%;height:60px;background-color:whitesmoke;border-bottom:0.2px black solid;margin-top:-10px">
                <span style="font-size: 12px;margin-left:5px">新的朋友</span><br />
                <a href="#" style="width: 100%;height:70%;" @click="toNew">
                    <div style="width: 100%;height:70%;background-color:khaki;color:black">
                        <i class="el-icon-circle-plus-outline" style="font-size:40px"></i>
                        <span style="font-size: 18px;margin-left:5px;position: absolute;margin-top:7px">新的朋友</span>
                        <el-badge :value="friendApplyList.length" :max="99" class="item" v-if="friendApplyList.length > 0">
                        </el-badge>
                        <el-badge class="item" v-if="friendApplyList.length == 0">
                        </el-badge>
                    </div>
                </a>
            </div>
            <div style="margin-top:15px"></div>
            <!--群聊-->
            <div style="width: 100%;">
                <span style="font-size: 12px;margin-left:5px">群聊</span><br />
                <a href="#" v-for="(g, index) in groups" @click="toGroupDetail(g.groupId)">
                    <div
                        :style="g.flag == true ? { backgroundColor: 'rgb(155, 255, 101)', color: 'black', width: '100%', height: '50px' } : { backgroundColor: 'whitesmoke', color: 'black', width: '100%', height: '50px' }">
                        <div style="width:20%;height:80%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                            <img :src="g.groupUrl" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="font-size: 18px;margin-left:5px;margin-top:15px;float:left">{{ g.groupNotes }}</div>
                    </div>
                </a>
            </div>
            <div style="margin-top:15px"></div>
            <!--好友-->
            <div style="width: 100%;" v-for="f in friends">
                <span style="font-size: 12px;margin-left:5px">{{ f.code }}</span><br />
                <a href="#" v-for="(x, index) in f.users" @click="toDetail(x.userId, index)">
                    <div
                        :style="x.flag == true ? { backgroundColor: 'rgb(155, 255, 101)', color: 'black', width: '100%', height: '50px' } : { backgroundColor: 'whitesmoke', color: 'black', width: '100%', height: '50px' }">
                        <div style="width:20%;height:80%;margin-left:10px;float:left;margin-top:6px;overflow: hidden;">
                            <img :src="x.fileUrl" width="100%" height="100%" style="object-fit: cover;" />
                        </div>
                        <div style="font-size: 18px;margin-left:5px;margin-top:15px;float:left">{{ x.firendName }}</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="right">
            <router-view @func="getInit" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Friend',
    data() {
        return {
            user: {
                userId: "",
                userName: ""
            },
            friends: [
            ],
            groups: [],
            sum:10,//新的朋友数量值
            friendApplyList:[],//申请人信息
        }
    },
    methods: {
        //详情页面
        toDetail(friendId, index) {
            /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 点击聊天框改变背景颜色*/
            //  for (let i in this.friends.users) {
            //     if (index == i) {
            //         this.friends.users[i].flag = !this.friends.users[i].flag
            //     } else {
            //         this.friends.users[i].flag = false;
            //     }
            // }
            // console.log(this.friends.users)
            this.$router.push({ name: "Detail", query: { friendId: friendId } });
        },
        //群聊详情页面
        toGroupDetail(id) {
            this.$router.push({ name: "GroupDetail", query: { groupId: id } });
        },
        //新的朋友页面
        toNew() {
            this.$router.push(`/new`);
        },
        //查询我的所有好友
        getFriendList() {
            this.$axios.post('user/allFriend', { 'firendName': '' }).then(resp => {
                this.friends = resp.data.data;
                this.getMyList();
                this.getGrouopList();
            });
        },
        //查询我的所有群聊
        getGrouopList() {
            this.$axios.post('group/myGroupList').then(resp => {
                this.groups = resp.data.data;
            });
        },
        //查询我的被申请数量
        getMyList() {
            this.$axios.post('friendApply/getMyList').then(resp => {
                this.friendApplyList = resp.data.data;
            });
        },
        //查找账号
        toSearch() {
            this.$router.push(`/search`);
        },
        //接收子路由传值,实现父路由页面刷新数据
        getInit(data) {
            if (data != null || !data) {
                this.getFriendList();
            }
        }
    },
    mounted() {
        //获得保存在本地的登录用户数据
        this.user = JSON.parse(localStorage.getItem('user'));
        //获得我的好友和群聊信息
        this.getFriendList();
    }
}
</script>

<style scoped>
.left {
    width: 30%;
    height: 100%;
    border-right: 0.5px black solid;
    margin-left: -9px;
    float: left;
    overflow-y: auto;
}

.right {
    width: 70%;
    height: 100%;
    float: right;
    margin-right: 6px;
}


/* 整个滚动条 */
.left::-webkit-scrollbar {
    width: 8px;
}

/* 滚动条里面滑块 */
.left::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: skyblue;
}

/*滚动条里面轨道*/
.left::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background: #eee;
}

a {
    text-decoration: none;
}

.item{
    margin-left:70px;
    margin-top:-35px
}
</style>